import React, { useEffect, useState } from 'react'
import PageTitles from './PageTitles'
import { fetchClassRecords, fetchParentRecords, fetchPickUPRecords } from '../firebase/fetchRecords';
import { Form, Modal, Spin, Pagination, Tooltip , notification} from 'antd';
import Header from '../header/Header';
import Sidebar from '../Sidebar/Sidebar';
import Select from 'react-select';
import { addDoc, collection, doc, updateDoc } from 'firebase/firestore';
import StartFirebase from '../firebase';
import { Link } from 'react-router-dom';
function Pickups() {
    const [tableData, setTableData]=useState([])
    const [parentsData, setParentsData]=useState([])
    const [classData, setClassData]=useState([])
    const [loading, setLoading]=useState(true)
    const [dropoffLoading, setDropoffLoading]=useState(true)
    const [addVehiclesModal, setAddVehicleModal]= useState(false)
    const [editVehicleModal, setEditVehicleModal]=useState(false)
    const [currentPage, setCurrentPage] = useState(1);
    const pageSize = 10; 
    const [sorter, setSorter] = useState({});
    const [sortOrder, setSortOrder] = useState(null);
    const [sortField, setSortField] = useState(null);
    const [form] = Form.useForm();
    const [formData, setFormData] = useState(null);
    const [addStudentLoading, setAddStudentLoading]=useState(false)
    const [vehiclesLoading, setAddVehiclesLoading]=useState(false)

    const handleChangeSort = (pagination, filters, sorter) => {

      setSorter(sorter);
  };

    const handleChangePage = (page) => {
        setCurrentPage(page);
    };
    const db = StartFirebase();

    useEffect(() => {
        fetchParentsData()
        fetchData();
        fetchClassData();
      }, []);
  
      const fetchData = async () => {
        setDropoffLoading(true)
        try {
          const data = await fetchPickUPRecords();
          setTableData(data);
          setDropoffLoading(false);
        } catch (error) {
          console.error('Error fetching data:', error);
        } finally {
          setDropoffLoading(false);
        }
      };
      const fetchParentsData = async () => {
        try {
          const data = await fetchParentRecords();
          setParentsData(data);
        } catch (error) {
          console.error('Error fetching data:', error);
        } finally {
          setLoading(false);
        }
      };
  
      const fetchClassData = async () => {
        try {
          const data = await fetchClassRecords();
          setClassData(data);
        } catch (error) {
          console.error('Error fetching data:', error);
        } finally {
          setLoading(false);
        }
      };

    const handleSort = (field) => {
      if (field === sortField) {
          setSortOrder((prevOrder) => (prevOrder === 'asc' ? 'desc' : 'asc'));
      } else {
          setSortField(field);
          setSortOrder('asc');
      }
  };

  const sortedData = tableData?.tableData ? [...tableData.tableData] : [];

  if (sortField && sortOrder) {
      sortedData.sort((a, b) => {
          const aValue = a.data[sortField];
          const bValue = b.data[sortField];

          if (sortOrder === 'asc') {
              return aValue.localeCompare(bValue);
          } else {
              return bValue.localeCompare(aValue);
          }
      });
  }
   
  const [searchTerm, setSearchTerm] = useState('');


    const handleEdit = (record) => {
      setFormData({
        ...record.data,
      });
  
      form.setFieldsValue({
        key: record.key,
        makeModel: record.data.makeModel,
        color: record.data.color,
        registrationNumber: record.data.registrationNumber,
        parentID: record.data.parentID,
        qrCode: record.data.qrCode,
      });
  
      setEditVehicleModal(true)
    };
    const [api, contextHolder] = notification.useNotification();
    const openNotificationWithIcon = (type) => {
        notification[type]({
          message: type === 'success' ? 'Success' : 'Error',
          description: type === 'success' ? 'Vehicle added successfully' : 'Failed to add vehicle',
        });
      };
      const openUpdateNotification = (type) => {
        notification[type]({
          message: type === 'success' ? 'Success' : 'Error',
          description: type === 'success' ? 'Vehicle updated successfully' : 'Failed to update vehicle',
        });
      };
  
        
    const vehiclessCollectionRef = collection(db, "vehicles");

    const addVehicle = async (values) => {
      setAddVehiclesLoading(true);
      try {
        const docRef = await addDoc(vehiclessCollectionRef, {
          color: values.color,
          makeModel: values.makeModel,
          registrationNumber: values.registrationNumber,
          parentID: values.parentID,
          qrCode: values.qrCode, 
          createdAt: new Date(),
          updatedAt: new Date(),
        });
    
        setAddVehiclesLoading(false);
        setAddVehicleModal(false);
        openNotificationWithIcon('success');
      } catch (error) {
        setAddVehiclesLoading(false);
        setAddVehicleModal(false);
        openNotificationWithIcon('error');
      }
    };
    

    const updateVehicle = async (id, updatedValues) => {
        setAddVehiclesLoading(true)
    
      try {
        await updateDoc(doc(vehiclessCollectionRef, id), {
          color: updatedValues.color,
          makeModel: updatedValues.makeModel,
          registrationNumber: updatedValues.registrationNumber,
          updatedAt: Date.now(),
        });
    
        setEditVehicleModal(false)
        setAddVehiclesLoading(false)
        fetchData()
        openUpdateNotification('success')
      } catch (error) {
        setAddVehiclesLoading(false)
        openUpdateNotification('error')
        setEditVehicleModal(false)
      }
    };
    const filteredData = sortedData.filter((record) => {
      return (
        record.data.studentName && record.data.studentName.toLowerCase().includes(searchTerm.toLowerCase()) ||
        record.data.driverName &&  record.data.driverName.toLowerCase().includes(searchTerm.toLowerCase()) ||
        record.data.formattedPickUpTime &&  record.data.formattedPickUpTime.toString().toLowerCase().includes(searchTerm.toLowerCase()) ||
        record.data.vehicleRegistration &&  record.data.vehicleRegistration.toLowerCase().includes(searchTerm.toLowerCase()) ||
        record.data.formattedDropOffTime &&  record.data.formattedDropOffTime.toLowerCase().includes(searchTerm.toLowerCase())
      );
  });
    const handleChangeSearch = (event) => {
      setSearchTerm(event.target.value);
      console.log(searchTerm)
  };
  return (
    <>
    {contextHolder}
    <Header/>
    <Sidebar/>
    <main id='main' className='main'>
        <PageTitles page='Drop Offs'/>
        <section className="section">
            <div className="row">
                <div className="col-lg-12">
                    <div className="card">
                        <div className="card-body">
                        <h5 className="card-title">Pick Ups</h5>
                        <div className="col-lg-3 d-flex justify-content-end">
                          <div class="input-group">
                            <input type="search" class="form-control rounded" placeholder="Search" aria-label="Search" aria-describedby="search-addon" value={searchTerm} onChange={handleChangeSearch}/>
                            <button type="button" class="btn btn-outline-primary" data-mdb-ripple-init><i class="bi bi-search"></i></button>
                          </div>
                        </div>
                        <Spin spinning={dropoffLoading}>
                          
                        <table className="table datatable">
                        <thead> 
                        <tr>
                            <th onClick={() => handleSort('studentName')}>Student Name </th>
                            <th onClick={() => handleSort('driverName')}>Guardian Name</th>
                            <th onClick={() => handleSort('formattedPickUpTime')}>Collection Time</th>
                            <th onClick={() => handleSort('vehicle')}>Vehicle Model</th>
                            <th onClick={() => handleSort('vehicleRegistration')}>Vehicle Registration</th>
                            <th onClick={() => handleSort('vehicleColor')}>Vehicle Color</th>
                        </tr>
                            </thead>
                            <tbody>
                            {filteredData.slice((currentPage - 1) * pageSize, currentPage * pageSize).map((record, key) => (

                              <tr key={key}>
                                <td>{record.data.studentName}</td>
                                <td>{record.data.driverName}</td>
                               <td>{record.data.formattedPickUpTime}</td>
                                <td>{record.data.vehicleModel}</td>
                                <td>{record.data.vehicleRegistration}</td>
                                <td>{record.data.vehicleColor}</td>

                              </tr>
                            ))}
                            </tbody>
                        </table>
                        <Pagination
                            current={currentPage}
                            onChange={handleChangePage}
                            total={tableData?.tableData?.length}
                            pageSize={pageSize}
                            showSizeChanger={false}
                            style={{ marginTop: '20px', textAlign: 'center' }}
                        />
                        </Spin>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </main>
</>
  )
}

export default Pickups
