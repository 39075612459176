import React from 'react'
import Logo from '../logo/Logo'
import SearchBar from '../SearchBar/SearchBar'
import Navbar from '../nav/Navbar'
import './header.css'
import NavAvatar from '../nav/NavAvatar'
function Header() {
  return (
    <header id='header' className='header fixed-top d-flex align-items-center'>
        {/* {logo} */}
        <Logo/>
        <Navbar/>
    </header>
  )
}

export default Header
