import React, { useEffect, useState } from 'react'
import PageTitles from './PageTitles'
import { fetchClassRecords, fetchParentRecords, fetchVehicleRecords } from '../firebase/fetchRecords';
import { Form, Modal, Spin, Pagination, Tooltip , notification} from 'antd';
import Header from '../header/Header';
import Sidebar from '../Sidebar/Sidebar';
import Select from 'react-select';
import { addDoc, collection, doc, updateDoc } from 'firebase/firestore';
import StartFirebase from '../firebase';
import QRCode from 'react-qr-code';

function Vehicles() {
    const [tableData, setTableData]=useState([])
    const [parentsData, setParentsData]=useState([])
    const [classData, setClassData]=useState([])
    const [loading, setLoading]=useState(true)
    const [addVehiclesModal, setAddVehicleModal]= useState(false)
    const [editVehicleModal, setEditVehicleModal]=useState(false)
    const [currentPage, setCurrentPage] = useState(1);
    const pageSize = 10; 
    const [sorter, setSorter] = useState({});
    const [sortOrder, setSortOrder] = useState(null);
    const [sortField, setSortField] = useState(null);
    const [form] = Form.useForm();
    const [formData, setFormData] = useState(null);
    const [addStudentLoading, setAddStudentLoading]=useState(false)
    const [vehiclesLoading, setAddVehiclesLoading]=useState(false)
    const [studentsTable, setStudentsTable]=useState([])
    const [studentsTableModal, setStudentsTableModal]=useState(false)
    const [searchTerm, setSearchTerm] = useState('');
    const [isLoading, setIsLoading]=useState(false)
    const handleChangeSort = (pagination, filters, sorter) => {
      setSorter(sorter);
  };

    const handleChangePage = (page) => {
        setCurrentPage(page);
    };
    const db = StartFirebase();

    useEffect(() => {
        fetchParentsData()
        fetchData();
        fetchClassData();
      }, []);
  
      const fetchData = async () => {
        setIsLoading(true)
        try {
          const data = await fetchVehicleRecords();
          setTableData(data);
          setIsLoading(false);
        } catch (error) {
          console.error('Error fetching data:', error);
        } finally {
          setIsLoading(false);
        }
      };
      const fetchParentsData = async () => {
        try {
          const data = await fetchParentRecords();
          setParentsData(data);
        } catch (error) {
          console.error('Error fetching data:', error);
        } finally {
          setLoading(false);
        }
      };
  
      const fetchClassData = async () => {
        try {
          const data = await fetchClassRecords();
          setClassData(data);
        } catch (error) {
          console.error('Error fetching data:', error);
        } finally {
          setLoading(false);
        }
      };

    const handleSort = (field) => {
      if (field === sortField) {
          setSortOrder((prevOrder) => (prevOrder === 'asc' ? 'desc' : 'asc'));
      } else {
          setSortField(field);
          setSortOrder('asc');
      }
  };

  const sortedData = tableData?.tableData ? [...tableData.tableData] : [];

  if (sortField && sortOrder) {
      sortedData.sort((a, b) => {
          const aValue = a.data[sortField];
          const bValue = b.data[sortField];

          if (sortOrder === 'asc') {
              return aValue.localeCompare(bValue);
          } else {
              return bValue.localeCompare(aValue);
          }
      });
  }
   


    const handleEdit = (record) => {
      setFormData({
        ...record.data,
      });
  
      form.setFieldsValue({
        key: record.key,
        makeModel: record.data.makeModel,
        color: record.data.color,
        registrationNumber: record.data.registrationNumber,
        parentID: record.data.parentID,
        qrCode: record.data.qrCode,
      });
  
      setEditVehicleModal(true)
    };
    const handleStudentDetailsEdit = (record) => {
      setStudentsTable(record)
  
      setStudentsTableModal(true)
    };
    const [api, contextHolder] = notification.useNotification();
    const openNotificationWithIcon = (type) => {
        notification[type]({
          message: type === 'success' ? 'Success' : 'Error',
          description: type === 'success' ? 'Vehicle added successfully' : 'Failed to add vehicle',
        });
      };
      const openUpdateNotification = (type) => {
        notification[type]({
          message: type === 'success' ? 'Success' : 'Error',
          description: type === 'success' ? 'Vehicle updated successfully' : 'Failed to update vehicle',
        });
      };
  
        
    const vehiclessCollectionRef = collection(db, "vehicles");

    const addVehicle = async (values) => {
      setAddVehiclesLoading(true);
      try {
        const currentTime = Date.now(); 
        const docRef = await addDoc(vehiclessCollectionRef, {
          color: values.color,
          makeModel: values.makeModel,
          registrationNumber: values.registrationNumber,
          parentID: values.parentID,
          qrCode: values.qrCode, 
          createdAt: currentTime, 
          updatedAt: currentTime, 
        });
        const vehicleID = docRef.id;
    
        await updateDoc(docRef, {
          vehicleID: vehicleID,
        });
    
        setAddVehiclesLoading(false);
        setAddVehicleModal(false);
        openNotificationWithIcon('success');
      } catch (error) {
        setAddVehiclesLoading(false);
        setAddVehicleModal(false);
        openNotificationWithIcon('error');
      }
    };
    
    

    const updateVehicle = async (id, updatedValues) => {
        setAddVehiclesLoading(true)
    
      try {
        await updateDoc(doc(vehiclessCollectionRef, id), {
          color: updatedValues.color,
          makeModel: updatedValues.makeModel,
          registrationNumber: updatedValues.registrationNumber,
          qrCode: updatedValues.qrCode,
          updatedAt: Date.now(),
        });
    
        setEditVehicleModal(false)
        setAddVehiclesLoading(false)
        fetchData()
        openUpdateNotification('success')
      } catch (error) {
        setAddVehiclesLoading(false)
        openUpdateNotification('error')
        setEditVehicleModal(false)
      }
    };
    const filteredData = sortedData.filter((record) => {
      return (
          record.data.makeModel.toLowerCase().includes(searchTerm.toLowerCase()) ||
          record.data.color.toLowerCase().includes(searchTerm.toLowerCase()) ||
          record.data.registrationNumber.toLowerCase().includes(searchTerm.toLowerCase()) ||
          record.data.parentName.toLowerCase().includes(searchTerm.toLowerCase())
      );
  });
    const handleChangeSearch = (event) => {
      setSearchTerm(event.target.value);
      console.log(searchTerm)
  };

  return (
    <>
    {contextHolder}
    <Header/>
    <Sidebar/>

    <Modal
        style={{ marginTop: '20px' }}
        visible={studentsTableModal}
        footer={false}
        closable={true}
        onCancel={() => {
            setStudentsTableModal(false)
        }}
        width={1200}
      >
            <div class="card">
            <div class="card-body">
              <h5 class="card-title">Students</h5>
                <Spin spinning={loading}>
                <table className="table datatable">
                        <thead> 
                        <tr>
                            <th onClick={() => handleSort('name')}>Name </th>
                            <th onClick={() => handleSort('level')}>Level</th>
                            <th onClick={() => handleSort('className')}>Class</th>
                            <th >Date of Birth</th>
                            <th onClick={() => handleSort('gender')}>Gender</th>
                        </tr>
                            </thead>
                            <tbody>
                            {studentsTable?.map((record, key) => (

                              <tr key={key}>
                                <td>{record.name}</td>
                                <td>{record.level}</td>
                                <td>{record.className}</td>
                                <td>{record.birthdate}</td>
                                <td>{record.gender}</td>
                              </tr>
                            ))}
                            </tbody>
                        </table>
                    </Spin>
                
                </div>
            </div>
      </Modal>
    <Modal
        style={{ marginTop: '20px' }}
        visible={addVehiclesModal}
        footer={false}
        closable={true}
        // className="modal fade" id="largeModal" tabindex="-1"
        onCancel={() => {
            setAddVehicleModal(false)
         
        }}
        width={800}
      >
            <div class="card">
            <div class="card-body">
              <h5 class="card-title">Add Vehicle</h5>
              <Spin spinning={vehiclesLoading}>
                <Form
                // form={form}
                  name="basic"
                  onFinish={(values) => addVehicle(values)}
                  initialValues={{ mode: 'all' }}
                >
                    <div class="row mb-3">
                    <label for="inputText" class="col-sm-2 col-form-label">Make Model</label>
                    <div class="col-sm-10">
                      <Form.Item
                        rules={[
                        {
                            required: true,
                            message: 'Please enter make model',
                        },
                        ]}
                        name="makeModel"
                      >
                        <input type="text" class="form-control"/>
                        </Form.Item>
                    </div>
                    </div>
                    <div class="row mb-3">
                    <label for="inputEmail" class="col-sm-2 col-form-label">Color</label>
                    <div class="col-sm-10">
                    <Form.Item
                        rules={[
                        {
                            required: true,
                            message: 'Please enter vehicle color',
                        },
                        ]}
                        name="color"
                      >
                        <input type="text" class="form-control"/>
                        </Form.Item>
                    </div>
                    </div>
                    
                    <div class="row mb-3">
                    <label for="inputNumber" class="col-sm-2 col-form-label">Registration Number</label>
                    <div class="col-sm-10">
                      <Form.Item
                          rules={[
                          {
                              required: true,
                              message: 'Please enter registration NUmber',
                          },
                          ]}
                          name="registrationNumber"
                        >
                        <input type="text" class="form-control"/>
                        </Form.Item>
                    </div>
                    </div>
                    <div class="row mb-3">
                    <label for="inputNumber" class="col-sm-2 col-form-label">QR Code</label>
                    <div class="col-sm-10">
                      <Form.Item
                          rules={[
                          {
                              required: true,
                              message: 'Please enter QR code ',
                          },
                          ]}
                          name="qrCode"
                        >
                        <input type="text" class="form-control"/>
                        </Form.Item>
                    </div>
                    </div>
                    
                    <div class="row mb-3">
                      <label for="inputNumber" class="col-sm-2 col-form-label">Owner(s)</label>
                      <div class="col-sm-10">
                        <Form.Item
                            rules={[
                            {
                                required: true,
                                message: 'Please select guardian(s)',
                            },
                            ]}
                            name="parentID"
                          >
                            <select class="form-select"  aria-label="Default select example">
                            <option value="">Select Owner</option>
                                {parentsData.tableData?.map((parent) => (
                                <option key={parent.key} value={parent.data.parentID}>
                                {parent.data.name}
                                </option>
                            ))}
                          </select>
                        </Form.Item>
                      </div>
                    </div>
                    <div class="row mb-3">
                    <label class="col-sm-2 col-form-label">Submit </label>
                    <div class="col-sm-10">
                        <button type="submit" class="btn button">Submit </button>
                    </div>
                    </div>
              </Form>
              </Spin>
                
                </div>
            </div>
      </Modal>
      <Modal
        style={{ marginTop: '20px' }}
        visible={editVehicleModal}
        footer={false}
        closable={true}
        // className="modal fade" id="largeModal" tabindex="-1"
        onCancel={() => {
            setEditVehicleModal(false)
         
        }}
        width={800}
      >
            <div class="card">
            <div class="card-body">
              <h5 class="card-title">Edit Vehicle</h5>
              <Spin spinning={vehiclesLoading}>
                <Form
                  form={form}
                  name="basic"
                  onFinish={(values) => updateVehicle(values.key, values)}
                  initialValues={{ mode: 'all' }}
                >
                     <Form.Item name="key" hidden={true}></Form.Item>
                    <div class="row mb-3">
                    <label for="inputText" class="col-sm-2 col-form-label">Make Model</label>
                    <div class="col-sm-10">
                      <Form.Item
                        rules={[
                        {
                            required: true,
                            message: 'Please enter make model',
                        },
                        ]}
                        name="makeModel"
                      >
                        <input type="text" class="form-control"/>
                        </Form.Item>
                    </div>
                    </div>
                    <div class="row mb-3">
                    <label for="inputEmail" class="col-sm-2 col-form-label">Color</label>
                    <div class="col-sm-10">
                    <Form.Item
                        rules={[
                        {
                            required: true,
                            message: 'Please enter vehicle color',
                        },
                        ]}
                        name="color"
                      >
                        <input type="text" class="form-control"/>
                        </Form.Item>
                    </div>
                    </div>
                    
                    <div class="row mb-3">
                    <label for="inputNumber" class="col-sm-2 col-form-label">Registration Number</label>
                    <div class="col-sm-10">
                      <Form.Item
                          rules={[
                          {
                              required: true,
                              message: 'Please enter registration NUmber',
                          },
                          ]}
                          name="registrationNumber"
                        >
                        <input type="text" class="form-control"/>
                        </Form.Item>
                    </div>
                    </div>
                    <div class="row mb-3">
                    <label for="inputNumber" class="col-sm-2 col-form-label">QR Code</label>
                    <div class="col-sm-10">
                      <Form.Item
                          rules={[
                          {
                              required: true,
                              message: 'Please enter QR code ',
                          },
                          ]}
                          name="qrCode"
                        >
                        <input type="text" class="form-control"/>
                        </Form.Item>
                    </div>
                    </div>
                    
                    {/* <div class="row mb-3">
                      <label for="inputNumber" class="col-sm-2 col-form-label">Owner(s)</label>
                      <div class="col-sm-10">
                        <Form.Item
                            rules={[
                            {
                                required: true,
                                message: 'Please select guardian(s)',
                            },
                            ]}
                            name="parentID"
                          >
                         <Select
                            
                            options={parentsData.tableData?.map((parent) => ({
                              value: parent.data.parentID,
                              label: parent.data.name,
                            })) || []}
                          />
                          </Form.Item>
                      </div>
                    </div> */}
                    <div class="row mb-3">
                    <label class="col-sm-2 col-form-label">Submit </label>
                    <div class="col-sm-10">
                        <button type="submit" class="btn button">Submit </button>
                    </div>
                    </div>
              </Form>
              </Spin>
                
                </div>
            </div>
      </Modal>
    
    <main id='main' className='main'>
        <PageTitles page='Vehicles'/>
        <section className="section">
            <div className="row">
                <div className="col-lg-12">
                    <div className="card">
                        <div className="card-body">
                        <h5 className="card-title">Vehicles</h5>
                        
                        <div class="card-body">
                          <button type="button" className="btn button" onClick={() => setAddVehicleModal(true)}><i class="bi bi-patch-plus"></i> Add Vehicles</button>
                        </div>
                        <div className="col-lg-3 text-end">
                          <div class="input-group">
                            <input type="search" class="form-control rounded" placeholder="Search" aria-label="Search" aria-describedby="search-addon" value={searchTerm} onChange={handleChangeSearch}/>
                            <button type="button" class="btn btn-outline-primary" data-mdb-ripple-init><i class="bi bi-search"></i></button>
                          </div>
                        </div>
                        <Spin spinning={isLoading}>
                          
                        <table className="table datatable">
                        <thead> 
                        <tr>
                            <th>Actions</th>
                            <th onClick={() => handleSort('makeModel')}>Make Model </th>
                            <th onClick={() => handleSort('color')}>Color</th>
                            <th onClick={() => handleSort('registrationNumber')}>Registration Number</th>
                            <th onClick={() => handleSort('parentName')}>Owner</th>
                            <th >QR Code</th>
                            <th onClick={() => handleSort('name')}>Students</th>
                            
                        </tr>
                            </thead>
                            <tbody>
                            {filteredData.slice((currentPage - 1) * pageSize, currentPage * pageSize).map((record, key) => (

                              <tr key={key}>
                                  <td>
                                    <Tooltip title="Edit">
                                      <button className="btn btn-outline btn-sm ml-2" onClick={() => handleEdit(record)}>
                                        <i class="bi bi-pencil-square"></i>
                                      </button>
                                    </Tooltip>
                                </td>
                                <td>{record.data.makeModel}</td>
                                <td>{record.data.color}</td>
                                <td>{record.data.registrationNumber}</td>
                                <td>{record.data.parentName}</td>
                                <td>
                                  <QRCode value={record.data.qrCode} size={72} /> {/* Changed here */}
                                </td>
                                <td>
                                  <button className="btn btn-outline btn-sm ml-2" onClick={() => handleStudentDetailsEdit(record.data.students)}>
                                    <i class="bi bi-eye"></i>
                                  </button>
                                </td>
                              </tr>
                            ))}
                            </tbody>
                        </table>
                        <Pagination
                            current={currentPage}
                            onChange={handleChangePage}
                            total={tableData?.tableData?.length}
                            pageSize={pageSize}
                            showSizeChanger={false}
                            style={{ marginTop: '20px', textAlign: 'center' }}
                            searchTerm={searchTerm}
                        />
                        </Spin>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </main>
</>
  )
}

export default Vehicles
