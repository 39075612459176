import React, { useEffect, useState } from 'react'
import './cards.css'
import { DropOffClassFive, DropOffClassFour, DropOffClassOne, DropOffClassSeven, DropOffClassSix, DropOffClassThree, DropOffClassTwo, classFIveData, classFourData, classOneData, classSevenData, classSixData, classThreeData, classTwoData, fetchAllStudentsRegister, fetchClassRecords, fetchDropOffCountsByClass, fetchDropOffRecords, fetchParentRecords, fetchSecurityPersonelRecords, fetchStudentsData, fetchStudentsRegister, fetchVehicleRecords, filterDropOffRecordsByDateAndClass } from '../../firebase/fetchRecords';
import { Form, Modal, Spin, Pagination, Tooltip , notification,} from 'antd';
import { Link } from 'react-router-dom';
function Cards() {
  const [vehicleCount, setVehicleCount] = useState(0);
  const [parentCount, setParentCount] = useState(0);
  const [studentCount, setStudentCount] = useState(0);
  const [dropoffCount, setDropoffCount] = useState(0);
  const [securityCount, setSecurityCOunt]= useState(0)
  const [class1, setClass1]=useState(0)
  const [class2, setClass2]=useState(0)
  const [class3, setClass3]=useState(0)
  const [class4, setClass4]=useState(0)
  const [class5, setClass5]=useState(0)
  const [class6, setClass6]=useState(0)
  const [class7, setClass7]=useState(0)

  const [drop1, setDrop1]=useState(0)
  const [drop2, setDrop2]=useState(0)
  const [drop3, setDrop3]=useState(0)
  const [drop4, setDrop4]=useState(0)
  const [drop5, setDrop5]=useState(0)
  const [drop6, setDrop6]=useState(0)
  const [drop7, setDrop7]=useState(0)

  const [classData, setClassData]=useState([])
  const [dropOffModal, setDropOffModal]=useState(false)
  const [loading, setLoading]=useState(true)
  const [classID, setClassID]= useState('')
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 10; 
  const [sorter, setSorter] = useState({});
  const [sortOrder, setSortOrder] = useState(null);
  const [sortField, setSortField] = useState(null);
  const [tableData, setTableData]=useState([])
  const [className, setClassName]=useState('')
  const handleChangeSort = (pagination, filters, sorter) => {

    setSorter(sorter);
};

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await fetchVehicleRecords();
        setVehicleCount(data.tableData.length);
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
      }
    };

    fetchData();
  }, []);


  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await fetchParentRecords();
        setParentCount(data.tableData.length);
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await fetchStudentsData();
        setStudentCount(data.tableData.length);
        console.log('counts'+data.counts[0])
        setLoading(false)
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await fetchDropOffRecords();
        setDropoffCount(data.tableData.length);
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await fetchSecurityPersonelRecords();
        setSecurityCOunt(data.tableData.length);
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
      }
    };

    fetchData();
    fetchClassData();
    fetchFiveData();
    fetchFourData();
    fetchSixData();
    fetchThreeData();
    fetchTwoData();
    fetchSevenData()
    fetchDrop1()
    fetchDrop2()
    fetchDrop3()
    fetchDrop4()
    fetchDrop5()
    fetchDrop6()
    fetchDrop7()
  }, []);

  const fetchClassData = async () => {
    try {
      const data = await classOneData();
      setClass1(data.tableData.length);
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  };
  const fetchTwoData = async () => {
    try {
      const data = await classTwoData();
      setClass2(data.tableData.length);
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  };
  const fetchThreeData = async () => {
    try {
      const data = await classThreeData();
      setClass3(data.tableData.length);
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  };
  const fetchFourData = async () => {
    try {
      const data = await classFourData();
      setClass4(data.tableData.length);
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  };
  const fetchFiveData = async () => {
    try {
      const data = await classFIveData();
      setClass5(data.tableData.length);
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  };
  const fetchSixData = async () => {
    try {
      const data = await classSixData();
      setClass6(data.tableData.length);
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  };
  const fetchSevenData = async () => {
    try {
      const data = await classSevenData();
      setClass7(data.tableData.length);
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  };

  const fetchDrop1 = async () => {
    try {
      const data = await DropOffClassOne();
      setDrop1(data.tableData.length);
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  };
  const fetchDrop2 = async () => {
    try {
      const data = await DropOffClassTwo();
      setDrop2(data.tableData.length);
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  };
  const fetchDrop3 = async () => {
    try {
      const data = await DropOffClassThree();
      setDrop3(data.tableData.length);
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  };
  const fetchDrop4 = async () => {
    try {
      const data = await DropOffClassFour();
      setDrop4(data.tableData.length);
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  };
  const fetchDrop5 = async () => {
    try {
      const data = await DropOffClassFive();
      setDrop5(data.tableData.length);
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  };

  const fetchDrop6 = async () => {
    try {
      const data = await DropOffClassSix();
      setDrop6(data.tableData.length);
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  };
  const fetchDrop7 = async () => {
    try {
      const data = await DropOffClassSeven();
      setDrop7(data.tableData.length);
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  };
  const handleEdit = (classID) => {
    setDropOffModal(true)
    dropOffDetails(classID)
  };
  const dropOffDetails = async (classID) => {
    setLoading(true)
    try {
      const data = await fetchStudentsRegister(classID);
      setTableData(data);
      setLoading(false)
      
    } catch (error) {
      console.error('Error fetching data:', error);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };
  const handleAllDetails = () => {
    setDropOffModal(true)
    allDropOffDetails()
  };
  const allDropOffDetails = async () => {
    setLoading(true)
    try {
      const data = await fetchAllStudentsRegister();
      setTableData(data);
      setLoading(false)
      
    } catch (error) {
      console.error('Error fetching data:', error);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };
  const handleChangePage = (page) => {
    setCurrentPage(page);
};
  const handleSort = (field) => {
    if (field === sortField) {
        setSortOrder((prevOrder) => (prevOrder === 'asc' ? 'desc' : 'asc'));
    } else {
        setSortField(field);
        setSortOrder('asc');
    }
};
const sortedData = tableData?.tableData ? [...tableData.tableData] : [];

if (sortedData.length > 0 && sortField && sortOrder) {
  sortedData.sort((a, b) => {
      const aValue = a.data[sortField] || ''; // Set a default value if the property is undefined
      const bValue = b.data[sortField] || ''; // Set a default value if the property is undefined

      if (sortOrder === 'asc') {
          return aValue.localeCompare(bValue);
      } else {
          return bValue.localeCompare(aValue);
      }
  });
}
const [searchTerm, setSearchTerm] = useState('');

const filteredData = sortedData.filter((record) => {
    return (
        (!record.data || !record.data.name || record.data.name.toLowerCase().includes(searchTerm.toLowerCase())) ||
        (!record.data.dropOffData || !record.data.dropOffData.vehicleModel || record.data.dropOffData.vehicleModel.toLowerCase().includes(searchTerm.toLowerCase())) ||
        (!record.data.dropOffData || !record.data.dropOffData.driverName || record.data.dropOffData.driverName.toLowerCase().includes(searchTerm.toLowerCase())) ||
        (!record.data.dropOffData || !record.data.dropOffData.formattedDropOffTime || record.data.dropOffData.formattedDropOffTime.toString().toLowerCase().includes(searchTerm.toLowerCase())) ||
        (!record.data.dropOffData || !record.data.dropOffData.vehicleRegistration || record.data.dropOffData.vehicleRegistration.toLowerCase().includes(searchTerm.toLowerCase())) ||
        (!record.data.dropOffData || !record.data.dropOffData.pickUpTime || record.data.dropOffData.pickUpTime.toLowerCase().includes(searchTerm.toLowerCase())) ||
        (!record.data.dropOffData || !record.data.dropOffData.present || record.data.dropOffData.present.toString().toLowerCase().includes(searchTerm.toLowerCase()))
    );
});

const handleChangeSearch = (event) => {
    setSearchTerm(event.target.value);
    console.log(searchTerm);
};

  return (
    <>
    <Modal
        style={{ marginTop: '20px' }}
        visible={dropOffModal}
        footer={false}
        closable={true}
        onCancel={() => {
            setDropOffModal(false)
        }}
        width={1200}
      >
              <div className="row">
                <div className="col-lg-12">
                    <div className="card">
                        <div className="card-body">
                        <h5 className="card-title">{className}</h5>
                        <div className="col-lg-3 d-flex justify-content-end">
                          <div class="input-group">
                            <input type="search" class="form-control rounded" placeholder="Search" aria-label="Search" aria-describedby="search-addon" value={searchTerm} onChange={handleChangeSearch}/>
                            <button type="button" class="btn btn-outline-primary" data-mdb-ripple-init><i class="bi bi-search"></i></button>
                          </div>
                        </div>
                        <Spin spinning={loading}>
                          
                        <table className="table datatable">
                        <thead> 
                        <tr>
                            <th onClick={() => handleSort('name')}>Student Name </th>
                            <th onClick={() => handleSort('driverName')}>Guardian Name</th>
                            <th onClick={() => handleSort('formattedDropOffTime')}>Drop Off Time</th>
                            {/* <th onClick={() => handleSort('formattedCollectionTime')}>Collection Time</th> */}
                            <th onClick={() => handleSort('vehicle')}>Vehicle Model</th>
                            <th onClick={() => handleSort('vehicleRegistration')}>Vehicle Registration</th>
                            <th onClick={() => handleSort('present')}>Present</th>
                        </tr>
                            </thead>
                            <tbody>
                              {filteredData.slice((currentPage - 1) * pageSize, currentPage * pageSize).map((record, key) => (
                              <tr key={key}>
                                <td>{record.data.name}</td>
                                <td>{record.data.dropOffData.driverName}</td>
                                <td>{record.data.dropOffData.formattedDropOffTime}</td>
                                {/* <td>{record.data.dropOffData.pickUpTime}</td> */}
                                <td>{record.data.dropOffData.vehicleModel}</td>
                                <td>{record.data.dropOffData.vehicleRegistration}</td>
                                <td>
                                  {record.data.dropOffData.present ? (
                                    <i className="bi bi-check-circle-fill text-success"></i> // Tick icon
                                  ) : (
                                    <i className="bi bi-x-circle-fill text-danger"></i> 
                                  )}
                                </td>
                              </tr>
                            ))}

                            </tbody>
                        </table>
                        <Pagination
                            current={currentPage}
                            onChange={handleChangePage}
                            total={tableData?.tableData?.length}
                            pageSize={pageSize}
                            showSizeChanger={false}
                            style={{ marginTop: '20px', textAlign: 'center' }}
                        />
                        </Spin>
                        </div>
                    </div>
                </div>
            </div>
      </Modal>
        <div className="row d-flex justify-content-center align-items-center'">
            <h4 className='dashHeader'>System Overview</h4>
          
            <div class="col-xxl-3 col-md-6">
            <Link to='/students'>
              <div class="card info-card sales-card">
                <div class="card-body">
                  <h5 class="card-title">Students</h5>

                  <div class="d-flex align-items-center">
                    <div class="card-icon rounded-circle d-flex align-items-center justify-content-center">
                    <i class="bi bi-person-raised-hand"></i>
                    </div>
                    <div class="ps-3">
                      <h6>{studentCount}</h6>
                      <span class="text-success small pt-1 fw-bold"></span> <span class="text-muted small pt-2 ps-1">Total students</span>

                    </div>
                </div>
                </div>
            </div>
            </Link>
            </div>
            
            <div class="col-xxl-3 col-md-6">
            <Link to='/parents'>
              <div class="card info-card sales-card">
                <div class="card-body">
                  <h5 class="card-title">Guardians</h5>

                  <div class="d-flex align-items-center">
                    <div class="card-icon rounded-circle d-flex align-items-center justify-content-center">
                    <i class="bi bi-person-standing-dress"></i>
                    </div>
                    <div class="ps-3">
                      <h6>{parentCount}</h6>
                      <span class="text-success small pt-1 fw-bold"></span> <span class="text-muted small pt-2 ps-1">Total Guardians</span>

                    </div>
                </div>
                </div>
            </div>
            </Link>
            </div>

            <div class="col-xxl-3 col-md-6">
            <Link to='/vehicles'>
              <div class="card info-card sales-card">
                <div class="card-body">
                  <h5 class="card-title">Vehicles</h5>

                  <div class="d-flex align-items-center">
                    <div class="card-icon rounded-circle d-flex align-items-center justify-content-center">
                    <i class="bi bi-car-front"></i>
                    </div>
                    <div class="ps-3">
                      <h6>{vehicleCount}</h6>
                      <span class="text-success small pt-1 fw-bold"></span> <span class="text-muted small pt-2 ps-1">Total Vehicles</span>

                    </div>
                </div>
                </div>
            </div>
            </Link>
            </div>

            <div class="col-xxl-3 col-md-6">
            <a onClick={() => { handleAllDetails(); setClassName('All Students'); }} >
              <div class="card info-card sales-card">
                <div class="card-body">
                  <h5 class="card-title">Present Students</h5>

                  <div class="d-flex align-items-center">
                    <div class="card-icon rounded-circle d-flex align-items-center justify-content-center">
                    <i class="bi bi-person-fill-check"></i>
                    </div>
                    <div class="ps-3">
                      <h6>{dropoffCount}/{studentCount}</h6>
                      <span class="text-success small pt-1 fw-bold"></span> <span class="text-muted small pt-2 ps-1">Present Students</span>

                    </div>
                </div>
                
                </div>
            </div>
            </a>
            </div>

            <div class="col-xxl-3 col-md-6">
            <Link to='/security-personnel'>
              <div class="card info-card sales-card">
                <div class="card-body">
                  <h5 class="card-title">Security Personnel</h5>

                  <div class="d-flex align-items-center">
                    <div class="card-icon rounded-circle d-flex align-items-center justify-content-center">
                    <i class="bi bi-shield-check"></i>
                    </div>
                    <div class="ps-3">
                      <h6>{securityCount}</h6>
                      <span class="text-success small pt-1 fw-bold"></span> <span class="text-muted small pt-2 ps-1">Security Personnel</span>

                    </div>
                </div>
                </div>
            </div>
            </Link>
            </div>
                <h4 className='dashHeader'>Class Summaries</h4>
            
              <div class="col-xxl-3 col-md-6">
              <a onClick={() => { handleEdit('Xc7zWZHc7yv08o9JUJDv'); setClassName('Class 1'); }} >
              <div class="card info-card sales-card">
                <div class="card-body">
                  <h5 class="card-title">Class 1</h5>

                  <div class="d-flex align-items-center">
                    <div class="card-icon rounded-circle d-flex align-items-center justify-content-center">
                    <i class="bi bi-book-half"></i>
                    </div>
                    <div class="ps-3">
                      <h6>{drop1}/{class1}</h6>
                      <span class="text-success small pt-1 fw-bold"></span> <span class="text-muted small pt-2 ps-1">Students</span>

                    </div>
                </div>
                </div>
            </div>
            </a>
            </div>
            
             <div class="col-xxl-3 col-md-6">
             <a onClick={() =>{ handleEdit('QYbZYGOA5QJRZENivWkh'); setClassName('Class 2')}} >
              <div class="card info-card sales-card">
                <div class="card-body">
                  <h5 class="card-title">Class 2</h5>

                  <div class="d-flex align-items-center">
                    <div class="card-icon rounded-circle d-flex align-items-center justify-content-center">
                    <i class="bi bi-book"></i>
                    </div>
                    <div class="ps-3">
                      <h6>{drop2}/{class2}</h6>
                      <span class="text-success small pt-1 fw-bold"></span> <span class="text-muted small pt-2 ps-1">Students</span>

                    </div>
                </div>
                </div>
            </div>
            </a>
            </div>
            
             <div class="col-xxl-3 col-md-6">
             <a onClick={() => {handleEdit('P4ffX5SldUDtxiokE4SJ'); setClassName('Class 3'); }} >
              <div class="card info-card sales-card">
                <div class="card-body">
                  <h5 class="card-title">Class 3</h5>

                  <div class="d-flex align-items-center">
                    <div class="card-icon rounded-circle d-flex align-items-center justify-content-center">
                    <i class="bi bi-book-half"></i>
                    </div>
                    <div class="ps-3">
                      <h6>{drop3}/{class3}</h6>
                      <span class="text-success small pt-1 fw-bold"></span> <span class="text-muted small pt-2 ps-1">Students</span>

                    </div>
                </div>
                </div>
            </div>
            </a>
            </div>
            
          <div class="col-xxl-3 col-md-6">
          <a onClick={() => {handleEdit('zZb5VQZeqEdWndQKPfrP'); setClassName('Class 4'); }} >
              <div class="card info-card sales-card">
                <div class="card-body">
                  <h5 class="card-title">Class 4</h5>

                  <div class="d-flex align-items-center">
                    <div class="card-icon rounded-circle d-flex align-items-center justify-content-center">
                    <i class="bi bi-book"></i>
                    </div>
                    <div class="ps-3">
                      <h6>{drop4}/{class4}</h6>
                      <span class="text-success small pt-1 fw-bold"></span> <span class="text-muted small pt-2 ps-1">Students</span>

                    </div>
                </div>
                </div>
            </div>
            </a> 
            </div>
           
             <div class="col-xxl-3 col-md-6">
             <a onClick={() => {handleEdit('uszRfV2J18RbkRpXb7c8'); setClassName('Class 5'); }} >
              <div class="card info-card sales-card">
                <div class="card-body">
                  <h5 class="card-title">Class 5</h5>

                  <div class="d-flex align-items-center">
                    <div class="card-icon rounded-circle d-flex align-items-center justify-content-center">
                    <i class="bi bi-book-half"></i>
                    </div>
                    <div class="ps-3">
                      <h6>{drop5}/{class5}</h6>
                      <span class="text-success small pt-1 fw-bold"></span> <span class="text-muted small pt-2 ps-1">Students</span>

                    </div>
                </div>
                </div>
            </div>
            </a>
            </div>
           
          <div class="col-xxl-3 col-md-6">
          <a onClick={() => {handleEdit('RvNLkbK5kU4qhBcvEdQR'); setClassName('Class 6'); }} >
              <div class="card info-card sales-card">
                <div class="card-body">
                  <h5 class="card-title">Class 6</h5>

                  <div class="d-flex align-items-center">
                    <div class="card-icon rounded-circle d-flex align-items-center justify-content-center">
                    <i class="bi bi-book"></i>
                    </div>
                    <div class="ps-3">
                      <h6>{drop6}/{class6}</h6>
                      <span class="text-success small pt-1 fw-bold"></span> <span class="text-muted small pt-2 ps-1">Students</span>

                    </div>
                </div>
                </div>
            </div>
            </a>
            </div>
            
           <div class="col-xxl-3 col-md-6">
           <a onClick={() => {handleEdit('Tl8eTeDj0WUzVxZAwHU7'); setClassName('Class 7'); }} > 
              <div class="card info-card sales-card">
                <div class="card-body">
                  <h5 class="card-title">Class 7</h5>

                  <div class="d-flex align-items-center">
                    <div class="card-icon rounded-circle d-flex align-items-center justify-content-center">
                    <i class="bi bi-book-half"></i>
                    </div>
                    <div class="ps-3">
                      <h6>{drop7}/{class7}</h6>
                      <span class="text-success small pt-1 fw-bold"></span> <span class="text-muted small pt-2 ps-1">Students</span>

                    </div>
                </div>
                </div>
            </div>
            </a>
            </div>    
        </div>
    </>
  )
}

export default Cards
