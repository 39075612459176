import React from 'react'
import './pageTitles.css'
function PageTitles({page}) {
  return (
    <div class="pagetitle">
      <h1>{page}</h1>
      
    </div>
  )
}

export default PageTitles
