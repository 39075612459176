import React from 'react'
import Cards from './Cards'
import './dashboard.css'
import RecentDropoffs from './RecentDropoffs'
import StudentStatistics from './StudentStatistics'
function Dashboard() {
  return (
    <section className="section dashboard">
        <div className="row">
          <div className="col-lg-12">
            <Cards/>
          </div>
          {/* <div class="col-lg-8 col-md-6" >
            <div class="row">
              <RecentDropoffs/>
            </div>
          </div> */}
          {/* <div class="col-lg-4 col-md-6">
            <div class="row">
              <div class="card">
                <div class="card-body pb-0">
                  <h5 class="card-title">Student Statistics</h5>
                  <StudentStatistics/>
                </div>
              </div>
            </div>
          </div> */}
        </div>
    </section>
  )
}

export default Dashboard
