import React, { useEffect, useState } from 'react'
import PageTitles from '../PageTitles'
import { fetchClassRecords, fetchParentRecords, fetchParentsByChildID, fetchStudentsData } from '../../firebase/fetchRecords';
import { Form, Modal, Spin, Pagination, Tooltip , notification} from 'antd';
import Header from '../../header/Header';
import Sidebar from '../../Sidebar/Sidebar';
import Select from 'react-select';
import { addDoc, collection, doc, updateDoc } from 'firebase/firestore';
import StartFirebase from '../../firebase';
function Students() {
    const [tableData, setTableData]=useState([])
    const [parentsData, setParentsData]=useState([])
    const [classData, setClassData]=useState([])
    const [loading, setLoading]=useState(true)
    const [addStudentsModal, setAddStudentsModal]= useState(false)
    const [editStudentsModal, setEditStudentsModal]=useState(false)
    const [currentPage, setCurrentPage] = useState(1);
    const pageSize = 10; 
    const [sorter, setSorter] = useState({});
    const [sortOrder, setSortOrder] = useState(null);
    const [sortField, setSortField] = useState(null);
    const [form] = Form.useForm();
    const [formData, setFormData] = useState(null);
    const [addStudentLoading, setAddStudentLoading]=useState(false)
    const [studentDetails, setStudentDetails]=useState(false)
    const [parentsTableData, setParentsTableData]=useState([])
    
    const handleChangeSort = (pagination, filters, sorter) => {
      setSorter(sorter);
  };

    const handleChangePage = (page) => {
        setCurrentPage(page);
    };
    const db = StartFirebase();

    useEffect(() => {
        fetchParentsData()
        fetchData();
        fetchClassData();
      }, []);
  
      const fetchData = async () => {
        try {
          const data = await fetchStudentsData();
          setTableData(data);
        } catch (error) {
          console.error('Error fetching data:', error);
        } finally {
          setLoading(false);
        }
      };
     
      const fetchParentsData = async () => {
        try {
          const data = await fetchParentRecords();
          setParentsData(data);
        } catch (error) {
          console.error('Error fetching data:', error);
        } finally {
          setLoading(false);
        }
      };
  
      const fetchClassData = async () => {
        try {
          const data = await fetchClassRecords();
          setClassData(data);
        } catch (error) {
          console.error('Error fetching data:', error);
        } finally {
          setLoading(false);
        }
      };

      const fetchParentsByID = async (childID) => {
        setLoading(true)
        try {
          const data = await fetchParentsByChildID(childID);
          setParentsTableData(data);
          setLoading(false);
        } catch (error) {
          setLoading(false);
          console.error('Error fetching data:', error);
        } finally {
          setLoading(false);
        }
      };

    const handleSort = (field) => {
      if (field === sortField) {
          setSortOrder((prevOrder) => (prevOrder === 'asc' ? 'desc' : 'asc'));
      } else {
          setSortField(field);
          setSortOrder('asc');
      }
  };

  const sortedData = tableData?.tableData ? [...tableData.tableData] : [];

  if (sortField && sortOrder) {
      sortedData.sort((a, b) => {
          const aValue = a.data[sortField];
          const bValue = b.data[sortField];

          if (sortOrder === 'asc') {
              return aValue.localeCompare(bValue);
          } else {
              return bValue.localeCompare(aValue);
          }
      });
  }
   
  const [searchTerm, setSearchTerm] = useState('');
 

    const handleEdit = (record) => {
      const birthdate = new Date(record.data.birthdate);
      const day = String(birthdate.getDate()).padStart(2, '0');
      const month = String(birthdate.getMonth() + 1).padStart(2, '0'); 
      const year = birthdate.getFullYear();
  
      const formattedDate = `${day}/${month}/${year}`;
      console.log(record.data.name)
      setFormData({
        ...record.data,
        birthdate: formattedDate,
      });
  
      form.setFieldsValue({
        key: record.key,
        name: record.data.name,
        level: record.data.level,
        class: record.data.class,
        birthdate: formattedDate,
        gender: record.data.gender,
        parentID: record.data.parentID,
      });
  
      setEditStudentsModal(true)
    };
    const handleStudentDetails = (record) => {
      fetchParentsByID(record.key)
      setStudentDetails(true)

    };
    const [api, contextHolder] = notification.useNotification();
    const openNotificationWithIcon = (type) => {
      notification[type]({
        message: type === 'success' ? 'Success' : 'Error',
        description: type === 'success' ? 'Student added successfully' : 'Failed to add Student',
      });
    };
    const openUpdateNotification = (type) => {
      notification[type]({
        message: type === 'success' ? 'Success' : 'Error',
        description: type === 'success' ? 'Student updated successfully' : 'Failed to update Student',
      });
    };
    
    const studentsCollectionRef = collection(db, "children");
    
    const addStudents = async (values) => {
      const selectedParentIds = values.parentID
        ? values.parentID.map(option => option.value).join(',')
        : '';
  
      setAddStudentLoading(true);
      try {
          const birthdateUnix = Date.parse(values.birthdate) / 1000; 
  
          setAddStudentLoading(true);
          const docRef= await addDoc(studentsCollectionRef, {
              name: values.name,
              level: values.level,
              birthdate: birthdateUnix, 
              parentID: selectedParentIds,
              classID: values.class,
              gender: values.gender,
              createdAt: Date.now(),
              updatedAt: Date.now(),
          });
          const childID = docRef.id;
    
          await updateDoc(docRef, {
            childID: childID,
          });
          setAddStudentLoading(false);
          setAddStudentsModal(false);
          openNotificationWithIcon('success');
      } catch (error) {
          setAddStudentLoading(false);
          setAddStudentsModal(false);
          openNotificationWithIcon('error');
      }
  };
  

    const updateStudent = async (studentId, updatedValues) => {
      setAddStudentLoading(true)
      const birthdateUnix = Date.parse(updatedValues.birthdate) / 1000; 
      try {
        await updateDoc(doc(studentsCollectionRef, studentId), {
          name: updatedValues.name,
          level: updatedValues.level,
          birthdate: birthdateUnix,
          // parentID: updatedValues.parentID.map(option => option.value).join(','),
          classID: updatedValues.class,
          gender: updatedValues.gender,
          updatedAt: Date.now(),
        });
    
        setEditStudentsModal(false)
        setAddStudentLoading(false)
        fetchData()
        openUpdateNotification('success')
      } catch (error) {
        setAddStudentLoading(false)
        openUpdateNotification('error')
        setEditStudentsModal(false)
      }
    };
    const filteredData = sortedData.filter((record) => {
      return (
          (record.data.name && record.data.name.toLowerCase().includes(searchTerm.toLowerCase())) ||
          (record.data.level && record.data.level.toLowerCase().includes(searchTerm.toLowerCase())) ||
          (record.data.class && record.data.class.toLowerCase().includes(searchTerm.toLowerCase())) ||
          (record.data.gender && record.data.gender.toLowerCase().includes(searchTerm.toLowerCase())) ||
          (record.data.birthdate && record.data.birthdate.toString().toLowerCase().includes(searchTerm.toLowerCase()))
      );
  });
  
    const handleChangeSearch = (event) => {
      setSearchTerm(event.target.value);
      console.log(searchTerm)
  };

  return (
    <>
    {contextHolder}
    <Header/>
    <Sidebar/>
    <Modal
        style={{ marginTop: '20px' }}
        visible={addStudentsModal}
        footer={false}
        closable={true}
        // className="modal fade" id="largeModal" tabindex="-1"
        onCancel={() => {
            setAddStudentsModal(false)
         
        }}
        width={800}
      >
            <div class="card">
            <div class="card-body">
              <h5 class="card-title">Add Students</h5>
              <Spin spinning={addStudentLoading}>
                <Form
                // form={form}
                  name="basic"
                  onFinish={(values) => addStudents(values)}
                  initialValues={{ mode: 'all' }}
                >
                    <div class="row mb-3">
                    <label for="inputText" class="col-sm-2 col-form-label">Full Name</label>
                    <div class="col-sm-10">
                      <Form.Item
                        rules={[
                        {
                            required: true,
                            message: 'Please enter student full name',
                        },
                        ]}
                        name="name"
                      >
                        <input type="text" class="form-control"/>
                        </Form.Item>
                    </div>
                    </div>
                    <div class="row mb-3">
                    <label for="inputEmail" class="col-sm-2 col-form-label">Level</label>
                    <div class="col-sm-10">
                    <Form.Item
                        rules={[
                        {
                            required: true,
                            message: 'Please enter student level',
                        },
                        ]}
                        name="level"
                      >
                        <input type="text" class="form-control"/>
                        </Form.Item>
                    </div>
                    </div>
                    <div class="row mb-3">
                    <label for="inputPassword" class="col-sm-2 col-form-label">Class</label>
                      <div class="col-sm-10">
                        <Form.Item
                            rules={[
                            {
                                required: true,
                                message: 'Please select student class',
                            },
                            ]}
                            name="class"
                          >
                          <select class="form-select"  aria-label="Default select example">
                          <option value=''>Select Class</option>
                            {classData.tableData?.map((classes) => (
                          <option key={classes.key} value={classes.key}>
                            {classes.data.name}
                          </option>
                    ))}
                          </select>
                          </Form.Item>
                      </div>
                    </div>
                    <div class="row mb-3">
                    <label for="inputNumber" class="col-sm-2 col-form-label">Date of Birth</label>
                    <div class="col-sm-10">
                      <Form.Item
                          rules={[
                          {
                              required: true,
                              message: 'Please enter student date of birth',
                          },
                          ]}
                          name="birthdate"
                        >
                        <input type="date" class="form-control"/>
                        </Form.Item>
                    </div>
                    </div>
                    <div class="row mb-3">
                    <label for="inputNumber" class="col-sm-2 col-form-label">Gender</label>
                    <div class="col-sm-10">
                       <Form.Item
                          rules={[
                          {
                              required: true,
                              message: 'Please enter student date of birth',
                          },
                          ]}
                          name="gender"
                        >
                      <select class="form-select"  aria-label="Default select example">
                          <option selected>Select Gender</option>
                          <option value="Male">Male</option>
                          <option value="Female">Female</option>
                        </select>
                        </Form.Item>
                    </div>
                    </div>
                    <div class="row mb-3">
                      <label for="inputNumber" class="col-sm-2 col-form-label">Guardian(s)</label>
                      <div class="col-sm-10">
                        <Form.Item
                            rules={[
                            {
                                required: true,
                                message: 'Please select guardian(s)',
                            },
                            ]}
                            name="parentID"
                          >
                         <Select
                            isMulti
                            options={parentsData.tableData?.map((parent) => ({
                              value: parent.data.parentID,
                              label: parent.data.name,
                            })) || []}
                          />
                          </Form.Item>
                      </div>
                    </div>
                    <div class="row mb-3">
                    <label class="col-sm-2 col-form-label">Submit </label>
                    <div class="col-sm-10">
                        <button type="submit" class="btn button">Submit </button>
                    </div>
                    </div>
              </Form>
              </Spin>
                
                </div>
            </div>
      </Modal>
      <Modal
        style={{ marginTop: '20px' }}
        visible={editStudentsModal}
        footer={false}
        closable={true}
        // className="modal fade" id="largeModal" tabindex="-1"
        onCancel={() => {
            setEditStudentsModal(false)
         
        }}
        width={800}
      >
            <div class="card">
            <div class="card-body">
              <h5 class="card-title">Edit Students</h5>
              <Spin spinning={addStudentLoading}>
                <Form
                 form={form}
                 name="basic"
                 onFinish={(values) => updateStudent(values.key, values)}
                 initialValues={{ mode: 'all' }}
                >
                   <Form.Item name="key" hidden={true}></Form.Item>
                    <div class="row mb-3">
                    <label for="inputText" class="col-sm-2 col-form-label">Full Name</label>
                    <div class="col-sm-10">
                      <Form.Item
                        rules={[
                        {
                            required: true,
                            message: 'Please enter student full name',
                        },
                        ]}
                        name="name"
                      >
                        <input type="text" class="form-control"/>
                        </Form.Item>
                    </div>
                    </div>
                    <div class="row mb-3">
                    <label for="inputEmail" class="col-sm-2 col-form-label">Level</label>
                    <div class="col-sm-10">
                    <Form.Item
                        rules={[
                        {
                            required: true,
                            message: 'Please enter student level',
                        },
                        ]}
                        name="level"
                      >
                        <input type="text" class="form-control"/>
                        </Form.Item>
                    </div>
                    </div>
                    <div class="row mb-3">
                    <label for="inputPassword" class="col-sm-2 col-form-label">Class</label>
                      <div class="col-sm-10">
                        <Form.Item
                            rules={[
                            {
                                required: true,
                                message: 'Please select student class',
                            },
                            ]}
                            name="class"
                          >
                          <select class="form-select"  aria-label="Default select example">
                            <option value=''>Select Class</option>
                            {classData.tableData?.map((classes) => (
                          <option key={classes.key} value={classes.key}>
                            {classes.data.name}
                          </option>
                    ))}
                          </select>
                          </Form.Item>
                      </div>
                    </div>
                    <div class="row mb-3">
                    <label for="inputNumber" class="col-sm-2 col-form-label">Date of Birth</label>
                    <div class="col-sm-10">
                      <Form.Item
                          rules={[
                          {
                              required: true,
                              message: 'Please enter student date of birth',
                          },
                          ]}
                          name="birthdate"
                        >
                        <input type="date" class="form-control"/>
                        </Form.Item>
                    </div>
                    </div>
                    <div class="row mb-3">
                    <label for="inputNumber" class="col-sm-2 col-form-label">Gender</label>
                    <div class="col-sm-10">
                       <Form.Item
                          rules={[
                          {
                              required: true,
                              message: 'Please enter student date of birth',
                          },
                          ]}
                          name="gender"
                        >
                      <select class="form-select"  aria-label="Default select example">
                          <option selected>Select Gender</option>
                          <option value="Male">Male</option>
                          <option value="Female">Female</option>
                        </select>
                        </Form.Item>
                    </div>
                    </div>
                    {/* <div class="row mb-3">
                      <label for="inputNumber" class="col-sm-2 col-form-label">Guardian(s)</label>
                      <div class="col-sm-10">
                        <Form.Item
                            rules={[
                            {
                                required: true,
                                message: 'Please select guardian(s)',
                            },
                            ]}
                            name="parentID"
                          >
                         <Select
                            isMulti
                            options={parentsData.tableData?.map((parent) => ({
                              value: parent.data.parentID,
                              label: parent.data.name,
                            })) || []}
                          />
                          </Form.Item>
                      </div>
                    </div> */}
                    <div class="row mb-3">
                    <label class="col-sm-2 col-form-label">Submit </label>
                    <div class="col-sm-10">
                        <button type="submit" class="btn button">Update </button>
                    </div>
                    </div>
              </Form>
              </Spin>
                
                </div>
            </div>
      </Modal>
    
      <Modal
        style={{ marginTop: '20px' }}
        visible={studentDetails}
        footer={false}
        closable={true}
        // className="modal fade" id="largeModal" tabindex="-1"
        onCancel={() => {
            setStudentDetails(false)
         
        }}
        width={1200}
      >
        <Spin spinning={loading}>
            <div className="card">
                <div className="card-body">
                <h5 className="card-title">Guardians</h5>
                <table className="table datatable">
                    <thead> 
                        <tr>
                            <th onClick={() => handleSort('name')}>Name </th>
                            <th onClick={() => handleSort('idNumber')}>ID Number</th>
                            <th onClick={() => handleSort('phoneNumber')}>Phone Number</th>
                            <th onClick={() => handleSort('email')}>Email</th>
                            <th onClick={() => handleSort('gender')}>Gender</th>
                            <th onClick={() => handleSort('childRelation')}>Relationship</th>
                            <th onClick={() => handleSort('makeModel')}>Vehicle Model</th>
                            <th onClick={() => handleSort('registrationNumber')}>Registration Number</th>
                            <th onClick={() => handleSort('color')}>Color</th>
                        </tr>
                      </thead>
                      <tbody>
                      {parentsTableData?.parentsData?.map((record, key) => (

                        <tr key={key}>
                          <td>{record.data.name}</td>
                          <td>{record.data.idNumber}</td>
                          <td>{record.data.phoneNumber}</td>
                          <td>{record.data.email}</td>
                          <td>{record.data.gender}</td>
                          <td>{record.data.childRelation}</td>
                          <td>{record.data.makeModel}</td>
                          <td>{record.data.registrationNumber}</td>
                          <td>{record.data.color}</td>
                        </tr>
                      ))}
                    </tbody>
                </table>
               </div>
              </div>
          </Spin>
      </Modal>

    <main id='main' className='main'>
        <PageTitles page='Students'/>
        <section className="section">
            <div className="row">
                <div className="col-lg-12">
                    <div className="card">
                        <div className="card-body">
                        <h5 className="card-title">Students</h5>
                        
                             <div class="card-body">
                                <button type="button" className="btn button" onClick={() => setAddStudentsModal(true)}><i class="bi bi-patch-plus"></i> Add Students</button>
                              
                        </div>
                        <div className="col-lg-3 text-end">
                          <div class="input-group">
                            <input type="search" class="form-control rounded" placeholder="Search" aria-label="Search" aria-describedby="search-addon" value={searchTerm} onChange={handleChangeSearch}/>
                            <button type="button" class="btn btn-outline-primary" data-mdb-ripple-init><i class="bi bi-search"></i></button>
                          </div>
                        </div>
                        <Spin spinning={loading}>
                          
                        <table className="table datatable">
                        <thead> 
                        <tr>
                            <th>Actions</th>
                            <th onClick={() => handleSort('name')}>Name </th>
                            <th onClick={() => handleSort('level')}>Level</th>
                            <th onClick={() => handleSort('class')}>Class</th>
                            <th >Date of Birth</th>
                            <th onClick={() => handleSort('gender')}>Gender</th>
                        </tr>
                            </thead>
                            <tbody>
                            {filteredData.slice((currentPage - 1) * pageSize, currentPage * pageSize).map((record, key) => (

                              <tr key={key}>
                                  <td>
                                    <Tooltip title="Overview">
                                      <button className="btn btn-outline btn-sm" onClick={() => handleStudentDetails(record)} >
                                        <i class="bi bi-eye"></i>
                                      </button>
                                    </Tooltip>
                                    <Tooltip title="Edit">
                                      <button className="btn btn-outline btn-sm ml-2" onClick={() => handleEdit(record)}>
                                        <i class="bi bi-pencil-square"></i>
                                      </button>
                                    </Tooltip>
                                </td>
                                <td>{record.data.name}</td>
                                <td>{record.data.level}</td>
                                <td>{record.data.class}</td>
                                <td>{record.data.birthdate}</td>
                                <td>{record.data.gender}</td>
                              </tr>
                            ))}
                            </tbody>
                        </table>
                        <Pagination
                            current={currentPage}
                            onChange={handleChangePage}
                            total={tableData?.tableData?.length}
                            pageSize={pageSize}
                            showSizeChanger={false}
                            style={{ marginTop: '20px', textAlign: 'center' }}
                        />
                        </Spin>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </main>
</>
  )
}

export default Students
