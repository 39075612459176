import { initializeApp } from 'firebase/app';
import { getFirestore} from 'firebase/firestore';
import { getAuth } from "firebase/auth";

function StartFirebase() {
  const firebaseConfig = {
    apiKey: "AIzaSyDtBD7C0VhDqeUwYruWaDE2UbNvTyBe6so",
    authDomain: "st-johns-ecd.firebaseapp.com",
    projectId: "st-johns-ecd",
    storageBucket: "st-johns-ecd.appspot.com",
    messagingSenderId: "224881130318",
    appId: "1:224881130318:web:8bc36f707306307b45fc1f"
  };

  const app = initializeApp(firebaseConfig);
  return getFirestore(app);
  
}

const firebaseConfig = {
  apiKey: "AIzaSyDtBD7C0VhDqeUwYruWaDE2UbNvTyBe6so",
  authDomain: "st-johns-ecd.firebaseapp.com",
  projectId: "st-johns-ecd",
  storageBucket: "st-johns-ecd.appspot.com",
  messagingSenderId: "224881130318",
  appId: "1:224881130318:web:8bc36f707306307b45fc1f"
};

const app = initializeApp(firebaseConfig);
export default StartFirebase;
export const auth = getAuth(app);