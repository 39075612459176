import React from 'react';
import Img from '../images/logo.png';

function NavAvatar() {
  return (
    <div className='d-flex align-items-center justify-content-between'>
      <a href='/' className='logo' style={{ display: 'flex', alignItems: 'center' }}>
        <img src={Img} alt="img" style={{ paddingTop: '20px' }} />
        <span className='nav-avatar' style={{ paddingTop: '40px', marginLeft: '10px' }}>ST Johns ECD</span>
      </a>
    </div>
  );
}

export default NavAvatar;
