import React, { useEffect, useState } from 'react'
import PageTitles from './PageTitles'
import { fetchClassRecords, fetchParentRecords, fetchSecurityPersonelRecords, fetchVehicleRecords } from '../firebase/fetchRecords';
import { Form, Modal, Spin, Pagination, Tooltip , notification} from 'antd';
import Header from '../header/Header';
import Sidebar from '../Sidebar/Sidebar';
import Select from 'react-select';
import { addDoc, collection, doc, updateDoc } from 'firebase/firestore';
import StartFirebase from '../firebase';
import axios from 'axios';
function SecurityPersonnel() {
    const [tableData, setTableData]=useState([])
    const [parentsData, setParentsData]=useState([])
    const [classData, setClassData]=useState([])
    const [loading, setLoading]=useState(true)
    const [addSecurityPersonnelModal, setAddSecurityPersonnelModal]= useState(false)
    const [editVehicleModal, setEditVehicleModal]=useState(false)
    const [currentPage, setCurrentPage] = useState(1);
    const pageSize = 10; 
    const [sorter, setSorter] = useState({});
    const [sortOrder, setSortOrder] = useState(null);
    const [sortField, setSortField] = useState(null);
    const [form] = Form.useForm();
    const [formData, setFormData] = useState(null);
    const [addStudentLoading, setAddStudentLoading]=useState(false)
    const [vehiclesLoading, setAddVehiclesLoading]=useState(false)
    const [editSecurityModal, setEditSecurityModal]=useState(false)
    const handleChangeSort = (pagination, filters, sorter) => {

      setSorter(sorter);
  };

    const handleChangePage = (page) => {
        setCurrentPage(page);
    };
    const db = StartFirebase();

    useEffect(() => {
        fetchData();
      }, []);
  
      const fetchData = async () => {
        try {
          const data = await fetchSecurityPersonelRecords();
          setTableData(data);
          setLoading(false);
        } catch (error) {
          console.error('Error fetching data:', error);
        } finally {
          setLoading(false);
        }
      };
     

    const handleSort = (field) => {
      if (field === sortField) {
          setSortOrder((prevOrder) => (prevOrder === 'asc' ? 'desc' : 'asc'));
      } else {
          setSortField(field);
          setSortOrder('asc');
      }
  };

  const sortedData = tableData?.tableData ? [...tableData.tableData] : [];

  if (sortField && sortOrder) {
      sortedData.sort((a, b) => {
          const aValue = a.data[sortField];
          const bValue = b.data[sortField];

          if (sortOrder === 'asc') {
              return aValue.localeCompare(bValue);
          } else {
              return bValue.localeCompare(aValue);
          }
      });
  }
   
  const [searchTerm, setSearchTerm] = useState('');


    const handleEdit = (record) => {
      setFormData({
        ...record.data,
      });
  
      form.setFieldsValue({
        key: record.key,
        name: record.data.name,
        phoneNumber: record.data.phoneNumber,
       
      });
  
      setEditSecurityModal(true)
    };
    const [api, contextHolder] = notification.useNotification();
    const openNotificationWithIcon = (type) => {
        notification[type]({
          message: type === 'success' ? 'Success' : 'Error',
          description: type === 'success' ? 'Security personnel added successfully' : 'Failed to add Security Personnel',
        });
      };
      const openPasswordNotification = (type) => {
        notification[type]({
          message: type === 'success' ? 'Success' : 'Error',
          description: type === 'success' ? '' : 'password and confirm password do not match',
        });
      };
      const openUpdateNotification = (type) => {
        notification[type]({
          message: type === 'success' ? 'Success' : 'Error',
          description: type === 'success' ? 'Parent updated successfully' : 'Failed to update parent',
        });
      };
        

    const apiUrl = 'https://sea-lion-app-ho4bu.ondigitalocean.app'; 
    const funKeyHeader = 'JumpingJoyfullyUnderMoonlight';
  
    const createUser = async (values) => {
        setLoading(true);
      const requestData = {
        name: values.name,
        phoneNumber: values.phoneNumber,
        password: values.password,
      };
      if (values.password === values.confirm_password) {
        try {
          const response = await axios.post(`${apiUrl}/create`, requestData, {
            headers: {
              "X-API-FunKey": funKeyHeader,
            },
          });
  
          if (response.status == 201) {
            setLoading(false);
            setAddSecurityPersonnelModal(false)
            openNotificationWithIcon("success");
          } else {
            setLoading(false);
            openNotificationWithIcon("error");
            setAddSecurityPersonnelModal(false)
          }
        } catch (error) {
            setLoading(false);
          openNotificationWithIcon("error");
          setAddSecurityPersonnelModal(false)
        }
      } else {
        setLoading(false);
        openPasswordNotification("error");
        setAddSecurityPersonnelModal(false)
      }
    };

    const securityCollectionRef = collection(db, "security_personnel");
    const updateSecurity = async (id, updatedValues) => {
      setLoading(true)
      console.log(updatedValues)
  
    try {
      await updateDoc(doc(securityCollectionRef, id), {
        name: updatedValues.name,
        phoneNumber: updatedValues.phoneNumber,
        updatedAt: Date.now(),
      });
  
      setLoading(false)
      openUpdateNotification('success')
    } catch (error) {
      setAddVehiclesLoading(false)
      openUpdateNotification('error')
      console.log(error)
      setLoading(false)
    }
  };
    const filteredData = sortedData.filter((record) => {
      return (
        record.data.name &&  record.data.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        record.data.phoneNumber &&  record.data.phoneNumber.toString().toLowerCase().includes(searchTerm.toLowerCase())
      );
  });
    const handleChangeSearch = (event) => {
      setSearchTerm(event.target.value);
      console.log(searchTerm)
    }
  return (
    <>
    {contextHolder}
    <Header/>
    <Sidebar/>
    <Modal
        style={{ marginTop: '20px' }}
        visible={addSecurityPersonnelModal}
        footer={false}
        closable={true}
        // className="modal fade" id="largeModal" tabindex="-1"
        onCancel={() => {
            setAddSecurityPersonnelModal(false)
         
        }}
        width={800}
      >
            <div class="card">
            <div class="card-body">
              <h5 class="card-title">Add Security Personnel</h5>
              <Spin spinning={loading}>
                <Form
                // form={form}
                  name="basic"
                  onFinish={(values) => createUser(values)}
                  initialValues={{ mode: 'all' }}
                >
                    <div class="row mb-3">
                    <label for="inputText" class="col-sm-2 col-form-label">Full Name</label>
                    <div class="col-sm-10">
                      <Form.Item
                        rules={[
                        {
                            required: true,
                            message: 'Please enter full name',
                        },
                        ]}
                        name="name"
                      >
                        <input type="text" class="form-control"/>
                        </Form.Item>
                    </div>
                    </div>
                    <div class="row mb-3">
                    <label for="inputEmail" class="col-sm-2 col-form-label">Phone Number</label>
                    <div class="col-sm-10">
                    <Form.Item
                        rules={[
                        {
                            required: true,
                            message: 'Please enter phone number ',
                        },
                        ]}
                        name="phoneNumber"
                      >
                        <input type="text" class="form-control"/>
                        </Form.Item>
                    </div>
                    </div>
                    
                    <div class="row mb-3">
                    <label for="inputNumber" class="col-sm-2 col-form-label">Password</label>
                    <div class="col-sm-10">
                      <Form.Item
                          rules={[
                          {
                              required: true,
                              message: 'Please enter password',
                          },
                          ]}
                          name="password"
                        >
                        <input type="password" class="form-control"/>
                        </Form.Item>
                    </div>
                    </div>
                    <div class="row mb-3">
                    <label for="inputNumber" class="col-sm-2 col-form-label">Confirm Password</label>
                    <div class="col-sm-10">
                      <Form.Item
                          rules={[
                          {
                              required: true,
                              message: 'Please confirm password ',
                          },
                          ]}
                          name="confirm_password"
                        >
                        <input type="password" class="form-control"/>
                        </Form.Item>
                    </div>
                    </div>
                   
                    <div class="row mb-3">
                    <label class="col-sm-2 col-form-label">Submit </label>
                    <div class="col-sm-10">
                        <button type="submit" class="btn button">Submit </button>
                    </div>
                    </div>
              </Form>
              </Spin>
                
                </div>
            </div>
      </Modal>
      <Modal
        style={{ marginTop: '20px' }}
        visible={editSecurityModal}
        footer={false}
        closable={true}
        // className="modal fade" id="largeModal" tabindex="-1"
        onCancel={() => {
            setEditSecurityModal(false)
         
        }}
        width={800}
      >
            <div class="card">
            <div class="card-body">
              <h5 class="card-title">Edit Security Personnel</h5>
              <Spin spinning={loading}>
                <Form
                form={form}
                  name="basic"
                  onFinish={(values) => updateSecurity(values.key, values)}
                  initialValues={{ mode: 'all' }}
                >
                    <div class="row mb-3">
                    <label for="inputText" class="col-sm-2 col-form-label">Full Name</label>
                    <div class="col-sm-10">
                      <Form.Item
                        rules={[
                        {
                            required: true,
                            message: 'Please enter full name',
                        },
                        ]}
                        name="name"
                      >
                        <input type="text" class="form-control"/>
                        </Form.Item>
                    </div>
                    </div>
                    <div class="row mb-3">
                    <label for="inputEmail" class="col-sm-2 col-form-label">Phone Number</label>
                    <div class="col-sm-10">
                    <Form.Item
                        rules={[
                        {
                            required: true,
                            message: 'Please enter phone number ',
                        },
                        ]}
                        name="phoneNumber"
                      >
                        <input type="text" class="form-control"/>
                        </Form.Item>
                    </div>
                    </div>
                   
                    <div class="row mb-3">
                    <label class="col-sm-2 col-form-label">Update </label>
                    <div class="col-sm-10">
                        <button type="submit" class="btn button">Update </button>
                    </div>
                    </div>
              </Form>
              </Spin>
                
                </div>
            </div>
      </Modal>
    
    
    <main id='main' className='main'>
        <PageTitles page='Security Personnel'/>
        <section className="section">
            <div className="row">
                <div className="col-lg-12">
                    <div className="card">
                        <div className="card-body">
                        <h5 className="card-title">Security Personel</h5>
                        
                             <div class="card-body">
                                <button type="button" className="btn button" onClick={() => setAddSecurityPersonnelModal(true)}><i class="bi bi-patch-plus"></i> Security Personnel</button>
                              
                        </div>
                        <div className="col-lg-3 d-flex justify-content-end">
                          <div class="input-group">
                            <input type="search" class="form-control rounded" placeholder="Search" aria-label="Search" aria-describedby="search-addon" value={searchTerm} onChange={handleChangeSearch}/>
                            <button type="button" class="btn btn-outline-primary" data-mdb-ripple-init><i class="bi bi-search"></i></button>
                          </div>
                        </div>
                        <Spin spinning={loading}>
                          
                        <table className="table datatable">
                        <thead> 
                        <tr>
                          <th>Actions</th>
                            <th onClick={() => handleSort('name')}>Name </th>
                            <th onClick={() => handleSort('phoneNumber')}>Phone Number</th>
                        </tr>
                            </thead>
                            <tbody>
                            {filteredData.slice((currentPage - 1) * pageSize, currentPage * pageSize).map((record, key) => (

                              <tr key={key}>
                                <td>
                                    <Tooltip title="Edit">
                                      <button className="btn btn-outline btn-sm ml-2" onClick={() => handleEdit(record)}>
                                        <i class="bi bi-pencil-square"></i>
                                      </button>
                                    </Tooltip>
                                </td>
            
                                <td>{record.data.name}</td>
                                <td>{record.data.phoneNumber}</td>
                              </tr>
                            ))}
                            </tbody>
                        </table>
                        <Pagination
                            current={currentPage}
                            onChange={handleChangePage}
                            total={tableData?.tableData?.length}
                            pageSize={pageSize}
                            showSizeChanger={false}
                            style={{ marginTop: '20px', textAlign: 'center' }}
                        />
                        </Spin>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </main>
</>
  )
}

export default SecurityPersonnel
