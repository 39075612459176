import React from 'react'
import './logo.css'
import Img from '../images/IAS_Gatepass_App-02.png'
function Logo() {
    const handleToggleSideBar = ()=>{
        document.body.classList.toggle('toggle-sidebar')
    };

  return (
    <div className='d-flex align-items-center justify-content-between'> 
      <a href='/' className='logo d-flex align-items-center'>
        <img src={Img} alt="img" />
        {/* <span className='d-none d-lg-block'>St Johns</span> */}
      </a>
      <i className="bi bi-list toggle-sidebar-btn"
      onClick={handleToggleSideBar}
      style={{color: 'white'}}
      ></i>
    </div>
  )
}

export default Logo
