import { collection, getDocs, doc, getDoc, query, where } from 'firebase/firestore';
import StartFirebase from './index';
import { isToday, format, startOfWeek, getDay, isThisWeek, getUnixTime, endOfWeek, isSameDay } from 'date-fns';

export const fetchStudentsData = async () => {
  const db = StartFirebase();
  const childrenCollection = collection(db, 'children');

  const querySnapshot = await getDocs(childrenCollection);

  let records = [];
  let counts = {};

  for (const doc of querySnapshot.docs) {
      let data = doc.data();
      let classID = data.classID;
      let classData = await fetchClassById(classID);

      data.class = classData ? classData.name : 'Unknown class';
      records.push({ key: doc.id, data: data });
  }

  return { tableData: records, counts: Object.values(counts) };
};

export const fetchParentsByChildID = async (childID) => {
  const db = StartFirebase();
  const childrenCollection = collection(db, 'children');

  const querySnapshot = await getDocs(childrenCollection);

  let parentsData = [];
  for (const doc of querySnapshot.docs) {
    const data = doc.data();
    const parentIDs = data.parentID.split(',').map(id => id.trim());

    if (doc.id === childID) {
      let parentRecords = [];
      for (const parentID of parentIDs) {
        const parentData = await fetchParentById(parentID);
        if (parentData) {
          const vehicleData = await fetchVehicleByParentID(parentID);
          // Set vehicle properties to parent data
          parentData.makeModel = vehicleData ? vehicleData.makeModel : 'Unknown model';
          parentData.registrationNumber = vehicleData ? vehicleData.registrationNumber : 'Unknown registration number';
          parentData.color = vehicleData ? vehicleData.color : 'Unknown color';

          parentRecords.push({ key: parentID, data: parentData });
        }
      }
      console.log(parentRecords);

      return { parentsData: parentRecords }; // Return data in the same format as fetchStudentsData
    }
  }
  
  return { parentsData: [] }; // Return an empty array if no parents are found for the given childID
};



export const fetchParentRecords = async () => {
    const db = StartFirebase();
    const childrenCollection = collection(db, 'parents');
  
    const querySnapshot = await getDocs(childrenCollection);
  
    let records = [];
    for (const doc of querySnapshot.docs) {
      let keyName = doc.id;
      let data = doc.data();
      records.push({ key: keyName, data: data });
    }
  
    return { tableData: records };
  };

  
  export const fetchVehicleRecords = async () => {
    const db = StartFirebase();
    const vehiclesCollection = collection(db, 'vehicles');
  
    const querySnapshot = await getDocs(vehiclesCollection);
  
    let records = [];
    for (const doc of querySnapshot.docs) {
        let keyName = doc.id;
        let data = doc.data();
        let parentID = data.parentID;
        let parentData = await fetchParentById(parentID);
  
        data.parentName = parentData ? parentData.name : 'Unknown Parent';
  
        let students = await fetchStudentsByParentID(parentID);
        data.students = await Promise.all(students.map(async (student) => {
          let classID = student.classID;
          let classData = await fetchClassById(classID);
        
          student.className = classData ? classData.name : 'Unknown class';
          return student;
        }));
        console.log(data)
        records.push({ key: keyName, data: data });
    }
    return { tableData: records };
  };
  
  
const fetchStudentsByParentID = async (parentID) => {
  const db = StartFirebase();
  const studentsCollection = collection(db, 'children');

  const querySnapshot = await getDocs(studentsCollection);

  let matchingStudents = [];
  for (const doc of querySnapshot.docs) {
      let studentData = doc.data();
      let studentParentIDs = studentData.parentID.split(',').map(id => id.trim()); 

      if (studentParentIDs.includes(parentID)) {
          matchingStudents.push(studentData);
      }
  }
  return matchingStudents;
};
const fetchVehicleByParentID = async (parentID) => {
  const db = StartFirebase();
  const vehiclesCollection = collection(db, 'vehicles');

  const q = query(vehiclesCollection, where('parentID', '==', parentID));
  const querySnapshot = await getDocs(q);
  
  if (!querySnapshot.empty) {
    const vehicleDoc = querySnapshot.docs[0];
    const vehicleData = vehicleDoc.data();
    return {
      makeModel: vehicleData.makeModel,
      registrationNumber: vehicleData.registrationNumber,
      color: vehicleData.color
    };
  } else {
    return null; // If no vehicle record found for the parent
  }
};
const fetchClassNameById = async (classID) => {
  const db = StartFirebase();
  const classesCollection = collection(db, 'class');
  const classDoc = await getDocs(classesCollection.doc(classID));

  if (classDoc.exists()) {
      return classDoc.data().name;
  } else {
      return null;
  }
};


export const fetchDropOffRecords = async () => {
  const db = StartFirebase();
  const dropOffCollection = collection(db, 'dropoffs');

  const querySnapshot = await getDocs(dropOffCollection);

  let records = [];

  for (const doc of querySnapshot.docs) {
    let keyName = doc.id;
    let data = doc.data();
    let vehicleID = data.vehicleID;
    let studentID = data.childID;
    let vehicleData = await fetVehicleDataById(vehicleID);
    let childData = await fetchStudentById(studentID);

    data.vehicleModel = vehicleData ? vehicleData.makeModel : 'Unknown Vehicle';
    data.studentName = childData ? childData.name : 'Unknown student';
    data.vehicleID = vehicleData ? vehicleData.vehicleID : 'unknown Vehicle';
    data.vehicleRegistration = vehicleData ? vehicleData.registrationNumber : 'unknown Vehicle';
    data.vehicleColor = vehicleData ? vehicleData.color : 'unknown Vehicle';
    
    let pickUpData = await fetchPickUpRecords(studentID); // Fetch pick-up data separately if needed
    
    // Convert Unix timestamps to JavaScript Date objects
    const dropOffTime = new Date(parseInt(data.dropOffTime));
    
    // Check if the drop-off time is today's date
    if (isToday(dropOffTime)) {
      data.formattedDropOffTime = format(dropOffTime, 'hh:mm a');
      // data.formattedPickUpTime = pickUpData ? pickUpData.formattedPickUpTime : 'None'
      records.push({ key: keyName, data: data });
    }
  }
  

  return { tableData: records };
};

export const fetchPickUPRecords = async () => {
  const db = StartFirebase();
  const dropOffCollection = collection(db, 'dropoffs');

  const querySnapshot = await getDocs(dropOffCollection);

  let records = [];

  for (const doc of querySnapshot.docs) {
    let keyName = doc.id;
    let data = doc.data();
    let vehicleID = data.vehicleID;
    let studentID = data.childID;
    let vehicleData = await fetVehicleDataById(vehicleID);
    let childData = await fetchStudentById(studentID);

    data.vehicleModel = vehicleData ? vehicleData.makeModel : 'Unknown Vehicle';
    data.studentName = childData ? childData.name : 'Unknown student';
    data.vehicleID = vehicleData ? vehicleData.vehicleID : 'unknown Vehicle';
    data.vehicleRegistration = vehicleData ? vehicleData.registrationNumber : 'unknown Vehicle';
    data.vehicleColor = vehicleData ? vehicleData.color : 'unknown Vehicle';
    
    
    // Convert Unix timestamps to JavaScript Date objects
    const pickUpTime = new Date(parseInt(data.pickUpTime));
    
    // Check if the drop-off time is today's date
    if (isToday(pickUpTime)) {
      data.formattedPickUpTime = format(pickUpTime, 'hh:mm a');
      records.push({ key: keyName, data: data });
    }
  }
  

  return { tableData: records };
};

  export const fetchPickUpRecords = async (childID) => {
      const db = StartFirebase();
      const dropOffCollection = collection(db, 'dropoffs');

      const querySnapshot = await getDocs(query(dropOffCollection, where('childID', '==', childID)));

      let records = [];

      for (const doc of querySnapshot.docs) {
        let keyName = doc.id;
        let data = doc.data();

        // Convert Unix timestamp to JavaScript Date object
        const pickUpTime = new Date(parseInt(data.pickUpTime));
        
        // Check if the pick-up time is today's date
        if (isToday(pickUpTime)) {
          data.formattedPickUpTime = format(pickUpTime, 'hh:mm a');
          records.push({ key: keyName, data: data });
        }
      }

      return records; // Return only the records, no need for additional object
  };


export const filterDropOffRecordsByDate = async (date) => {
    const db = StartFirebase();
    const dropOffCollection = collection(db, 'dropoffs');
  
    const querySnapshot = await getDocs(dropOffCollection);
  
    let records=[];
  
    for (const doc of querySnapshot.docs) {
      let keyName = doc.id;
      let data = doc.data();
      let vehicleID = data.vehicleID;
      let studentID = data.childID;
      let vehicleData = await fetchVehicleById(vehicleID);
      let childData = await fetchStudentById(studentID);
  
      data.vehicleModel = vehicleData ? vehicleData.makeModel : 'Unknown Vehicle';
      data.studentName = childData ? childData.name : 'Unknown student';
  
      const dropOffTime = new Date(parseInt(data.dropOffTime));
  
      if (isSameDay(dropOffTime, date)) {
        data.formattedDropOffTime = format(dropOffTime, 'hh:mm a');
        
        records.push({ key: keyName, data: data });
      }
    }
  
    return { tableData: records };
};
  
  export const fetchParentById = async (parentID) => {
    const db = StartFirebase();
    const parentsCollection = collection(db, 'parents');
  
    const docSnapshot = await getDoc(doc(parentsCollection, parentID));
  
    if (docSnapshot.exists()) {
      return docSnapshot.data();
    } else {
      throw new Error(`Parent with ID ${parentID} not found`);
    }
  };

  export const fetchVehicleById = async (parentID) => {
    const db = StartFirebase();
    const vehiclesCollection = collection(db, 'vehicles');

    const queries = query(vehiclesCollection, where('parentID', '==', parentID));

    const querySnapshot = await getDocs(queries);

    if (!querySnapshot.empty) {
        const docSnapshot = querySnapshot.docs[0];
        return docSnapshot.data();
    } else {
        return null;
    }
};

export const fetVehicleDataById = async (vehicleID) => {
  const db = StartFirebase();
  const vehiclesCollection = collection(db, 'vehicles');

  const docSnapshot = await getDoc(doc(vehiclesCollection, vehicleID));

  if (docSnapshot.exists()) {
    return docSnapshot.data();
  } else {
    throw new Error(`student with ID ${vehicleID} not found`);
  }
};



  export const fetchStudentById = async (studentID) => {
    const db = StartFirebase();
    const studentCollection = collection(db, 'children');
  
    const docSnapshot = await getDoc(doc(studentCollection, studentID));
  
    if (docSnapshot.exists()) {
      return docSnapshot.data();
    } else {
      throw new Error(`student with ID ${studentID} not found`);
    }
  };
  
  export const fetchStudentsPerParent = async (parentID) => {
    const db = StartFirebase();
    const childrenCollection = collection(db, 'children');
  
    const querySnapshot = await getDocs(childrenCollection);
    
    let records = [];
    for (const doc of querySnapshot.docs) {
      let keyName = doc.id;
      let data = doc.data();
      let documentParentIDs = data.parentID.split(',');
      let classID = data.classID;
      let classData = await fetchClassById(classID);
    
      data.class = classData ? classData.name : 'Unknown class';
      if (documentParentIDs.includes(parentID)) {
        let parentData = await fetchParentById(parentID);
  
        data.parentName = parentData ? parentData.name : 'Unknown Parent';
        data.parentNumber = parentData ? parentData.phoneNumber : 'Unknown Parent';
  
        records.push({ key: keyName, data: data });
      }
    }
  
    return { tableData: records };
  };
  

  export const fetchParentDetails = async (parentID) => {
    const db = StartFirebase();
    const childrenCollection = collection(db, 'parents');
  
    const q = query(childrenCollection, where('parentID', '==', parentID));
    const querySnapshot = await getDocs(q);
  
    let records = [];
    for (const doc of querySnapshot.docs) {
      let keyName = doc.id;
      let data = doc.data();
      records.push({ key: keyName, data: data });
    }
  
    return { tableData: records };
  };

  export const fetchClassRecords = async () => {
    const db = StartFirebase();
    const classCollection = collection(db, 'class');
  
    const querySnapshot = await getDocs(classCollection);
  
    let records= [];
    for (const doc of querySnapshot.docs) {
      let keyName = doc.id;
      let data = doc.data();
      records.push({ key: keyName, data: data });
    }
  
    return { tableData: records };
  };

  export const fetchClassById = async (classID) => {
    const db = StartFirebase();
    const classCollection = collection(db, 'class');
  
    const docSnapshot = await getDoc(doc(classCollection, classID));
  
    if (docSnapshot.exists()) {
      return docSnapshot.data();
    } else {
      return null
    }
};

export const fetchStudentsByGender = async () => {
  const db = StartFirebase();
  const childrenCollection = collection(db, 'children');

  const maleQuery = query(childrenCollection, where('gender', '==', 'Male'));
  const femaleQuery = query(childrenCollection, where('gender', '==', 'Female'));

  const maleSnapshot = await getDocs(maleQuery);
  const femaleSnapshot = await getDocs(femaleQuery);

  const maleCount = maleSnapshot.size;
  const femaleCount = femaleSnapshot.size;

  return { male: maleCount, female: femaleCount };
};


export const fetchParentRecordsByRelation = async () => {
  const db = StartFirebase();
  const parentsCollection = collection(db, 'parents');

  const querySnapshot = await getDocs(query(parentsCollection, where('childRelation', 'in', ['father', 'mother'])));
  let records = [];
  for (const doc of querySnapshot.docs) {
    let keyName = doc.id;
    let data = doc.data();
    records.push({ key: keyName, data: data });
  }

  return { tableData: records };
};

export const fetchThirdPart = async () => {
  const db = StartFirebase();
  const parentsCollection = collection(db, 'parents');

  const querySnapshot = await getDocs(query(parentsCollection, where('childRelation', 'in', ['other'])));
  let records = [];
  for (const doc of querySnapshot.docs) {
    let keyName = doc.id;
    let data = doc.data();
    records.push({ key: keyName, data: data });
  }

  return { tableData: records };
};

export const fetchThirdPartDrivers = async () => {
  const db = StartFirebase();
  const parentsCollection = collection(db, 'parents');

  const querySnapshot = await getDocs(query(parentsCollection, where('childRelation', 'in', ['driver'])));
  let records = [];
  for (const doc of querySnapshot.docs) {
    let keyName = doc.id;
    let data = doc.data();
    records.push({ key: keyName, data: data });
  }

  return { tableData: records };
};

export const fetchSecurityPersonelRecords = async () => {
  const db = StartFirebase();
  const securityCollection = collection(db, 'security_personnel');

  const querySnapshot = await getDocs(securityCollection);

  let records= [];
  for (const doc of querySnapshot.docs) {
    let keyName = doc.id;
    let data = doc.data();
    records.push({ key: keyName, data: data });
  }

  return { tableData: records };
};



export const fetchDropOffCountsByClass = async () => {
  const db = StartFirebase();
  const dropOffCollection = collection(db, 'dropoffs');

  const querySnapshot = await getDocs(dropOffCollection);

  let classCounts = {};

  for (const doc of querySnapshot.docs) {
      let data = doc.data();
      const dropOffTime = new Date(parseInt(data.dropOffTime));

      if (isToday(dropOffTime)) {
          let studentID = data.childID;
          let childData = await fetchStudentById(studentID);
          const classID = childData.classID;

          if (classCounts[classID]) {
              classCounts[classID].present += 1;
          } else {
              classCounts[classID] = { present: 1, total: 0 };
          }

          classCounts[classID].total += 1;
      }
  }

  // Calculate fractions
  for (const classID in classCounts) {
      const { present, total } = classCounts[classID];
      classCounts[classID].fraction = `${present}/${total}`;
  }
  console.log('counts'+classCounts)

  return classCounts;
};

export const classOneData = async () => {
  const db = StartFirebase();
  const parentsCollection = collection(db, 'children');

  const querySnapshot = await getDocs(query(parentsCollection, where('classID', 'in', ['Xc7zWZHc7yv08o9JUJDv'])));
  let records = [];
  for (const doc of querySnapshot.docs) {
    let keyName = doc.id;
    let data = doc.data();
    records.push({ key: keyName, data: data });
  }

  return { tableData: records };
};
export const classTwoData = async () => {
  const db = StartFirebase();
  const parentsCollection = collection(db, 'children');

  const querySnapshot = await getDocs(query(parentsCollection, where('classID', 'in', ['QYbZYGOA5QJRZENivWkh'])));
  let records = [];
  for (const doc of querySnapshot.docs) {
    let keyName = doc.id;
    let data = doc.data();
    records.push({ key: keyName, data: data });
  }

  return { tableData: records };
};

export const classThreeData = async () => {
  const db = StartFirebase();
  const parentsCollection = collection(db, 'children');

  const querySnapshot = await getDocs(query(parentsCollection, where('classID', 'in', ['P4ffX5SldUDtxiokE4SJ'])));
  let records = [];
  for (const doc of querySnapshot.docs) {
    let keyName = doc.id;
    let data = doc.data();
    records.push({ key: keyName, data: data });
  }

  return { tableData: records };
};

export const classFourData = async () => {
  const db = StartFirebase();
  const parentsCollection = collection(db, 'children');

  const querySnapshot = await getDocs(query(parentsCollection, where('classID', 'in', ['zZb5VQZeqEdWndQKPfrP'])));
  let records = [];
  for (const doc of querySnapshot.docs) {
    let keyName = doc.id;
    let data = doc.data();
    records.push({ key: keyName, data: data });
  }

  return { tableData: records };
};

export const classFIveData = async () => {
  const db = StartFirebase();
  const parentsCollection = collection(db, 'children');

  const querySnapshot = await getDocs(query(parentsCollection, where('classID', 'in', ['uszRfV2J18RbkRpXb7c8'])));
  let records = [];
  for (const doc of querySnapshot.docs) {
    let keyName = doc.id;
    let data = doc.data();
    records.push({ key: keyName, data: data });
  }

  return { tableData: records };
};

export const classSixData = async () => {
  const db = StartFirebase();
  const parentsCollection = collection(db, 'children');

  const querySnapshot = await getDocs(query(parentsCollection, where('classID', 'in', ['RvNLkbK5kU4qhBcvEdQR'])));
  let records = [];
  for (const doc of querySnapshot.docs) {
    let keyName = doc.id;
    let data = doc.data();
    records.push({ key: keyName, data: data });
  }

  return { tableData: records };
};

export const classSevenData = async () => {
  const db = StartFirebase();
  const parentsCollection = collection(db, 'children');

  const querySnapshot = await getDocs(query(parentsCollection, where('classID', 'in', ['Tl8eTeDj0WUzVxZAwHU7'])));
  let records = [];
  for (const doc of querySnapshot.docs) {
    let keyName = doc.id;
    let data = doc.data();
    records.push({ key: keyName, data: data });
  }

  return { tableData: records };
};

export const DropOffClassOne = async () => {
  const db = StartFirebase();
  const dropOffCollection = collection(db, 'dropoffs');

  const querySnapshot = await getDocs(dropOffCollection);

  let records = [];

  for (const doc of querySnapshot.docs) {
    let keyName = doc.id;
    let data = doc.data();
    let studentID = data.childID;
    let childData = await fetchStudentById(studentID);
    const dropOffTime = new Date(parseInt(data.dropOffTime));
    console.log(dropOffTime)
    
    // Check if the drop-off time is today's date
    if (isToday(dropOffTime)) {
      if (childData && childData.classID === 'Xc7zWZHc7yv08o9JUJDv') {          
        records.push({ key: keyName, data: data });
          }
      
      console.log(data)
    }
  }

  return { tableData: records };
};

export const DropOffClassTwo = async () => {
  const db = StartFirebase();
  const dropOffCollection = collection(db, 'dropoffs');

  const querySnapshot = await getDocs(dropOffCollection);

  let records = [];

  for (const doc of querySnapshot.docs) {
    let keyName = doc.id;
    let data = doc.data();
    let studentID = data.childID;
    let childData = await fetchStudentById(studentID);
    const dropOffTime = new Date(parseInt(data.dropOffTime));
    console.log(dropOffTime)
    
    // Check if the drop-off time is today's date
    if (isToday(dropOffTime)) {
      if (childData && childData.classID === 'QYbZYGOA5QJRZENivWkh') {          
        records.push({ key: keyName, data: data });
          }
      
      console.log(data)
    }
  }

  return { tableData: records };
};

export const DropOffClassThree = async () => {
  const db = StartFirebase();
  const dropOffCollection = collection(db, 'dropoffs');

  const querySnapshot = await getDocs(dropOffCollection);

  let records = [];

  for (const doc of querySnapshot.docs) {
    let keyName = doc.id;
    let data = doc.data();
    let studentID = data.childID;
    let childData = await fetchStudentById(studentID);
    const dropOffTime = new Date(parseInt(data.dropOffTime));
    console.log(dropOffTime)
    
    // Check if the drop-off time is today's date
    if (isToday(dropOffTime)) {
      if (childData && childData.classID === 'P4ffX5SldUDtxiokE4SJ') {          
        records.push({ key: keyName, data: data });
          }
      
      console.log(data)
    }
  }

  return { tableData: records };
};

export const DropOffClassFour = async () => {
  const db = StartFirebase();
  const dropOffCollection = collection(db, 'dropoffs');

  const querySnapshot = await getDocs(dropOffCollection);

  let records = [];

  for (const doc of querySnapshot.docs) {
    let keyName = doc.id;
    let data = doc.data();
    let studentID = data.childID;
    let childData = await fetchStudentById(studentID);
    const dropOffTime = new Date(parseInt(data.dropOffTime));
    console.log(dropOffTime)
    
    // Check if the drop-off time is today's date
    if (isToday(dropOffTime)) {
      if (childData && childData.classID === 'zZb5VQZeqEdWndQKPfrP') {          
        records.push({ key: keyName, data: data });
          }
      
      console.log(data)
    }
  }

  return { tableData: records };
};

export const DropOffClassFive = async () => {
  const db = StartFirebase();
  const dropOffCollection = collection(db, 'dropoffs');

  const querySnapshot = await getDocs(dropOffCollection);

  let records = [];

  for (const doc of querySnapshot.docs) {
    let keyName = doc.id;
    let data = doc.data();
    let studentID = data.childID;
    let childData = await fetchStudentById(studentID);
    const dropOffTime = new Date(parseInt(data.dropOffTime));
    console.log(dropOffTime)
    
    // Check if the drop-off time is today's date
    if (isToday(dropOffTime)) {
      if (childData && childData.classID === 'uszRfV2J18RbkRpXb7c8') {          
        records.push({ key: keyName, data: data });
          }
      
      console.log(data)
    }
  }

  return { tableData: records };
};

export const DropOffClassSix = async () => {
  const db = StartFirebase();
  const dropOffCollection = collection(db, 'dropoffs');

  const querySnapshot = await getDocs(dropOffCollection);

  let records = [];

  for (const doc of querySnapshot.docs) {
    let keyName = doc.id;
    let data = doc.data();
    let studentID = data.childID;
    let childData = await fetchStudentById(studentID);
    const dropOffTime = new Date(parseInt(data.dropOffTime));
    console.log(dropOffTime)
    
    // Check if the drop-off time is today's date
    if (isToday(dropOffTime)) {
      if (childData && childData.classID === 'RvNLkbK5kU4qhBcvEdQR') {          
        records.push({ key: keyName, data: data });
          }
      
      console.log(data)
    }
  }

  return { tableData: records };
};

export const DropOffClassSeven = async () => {
   const db = StartFirebase();
  const dropOffCollection = collection(db, 'dropoffs');

  const querySnapshot = await getDocs(dropOffCollection);

  let records = [];

  for (const doc of querySnapshot.docs) {
    let keyName = doc.id;
    let data = doc.data();
    let studentID = data.childID;
    let childData = await fetchStudentById(studentID);
    const dropOffTime = new Date(parseInt(data.dropOffTime));
    console.log(dropOffTime)
    
    // Check if the drop-off time is today's date
    if (isToday(dropOffTime)) {
      if (childData && childData.classID === 'Tl8eTeDj0WUzVxZAwHU7') {          
        records.push({ key: keyName, data: data });
          }
      
      console.log(data)
    }
  }

  return { tableData: records };
};

export const filterDropOffRecordsByDateAndClass = async (classID) => {
  const db = StartFirebase();
  const dropOffCollection = collection(db, 'dropoffs');

  const querySnapshot = await getDocs(dropOffCollection);

  let records = [];

  for (const doc of querySnapshot.docs) {
      let data = doc.data();
      let vehicleID = data.vehicleID;
      let studentID = data.childID;
      let vehicleData = await fetchVehicleById(vehicleID);
      let childData = await fetchStudentById(studentID);

      data.vehicleModel = vehicleData ? vehicleData.makeModel : 'Unknown Vehicle';
      data.studentName = childData ? childData.name : 'Unknown student';

      const dropOffTime = new Date(parseInt(data.dropOffTime));

      if (isToday(dropOffTime)) {
          if (childData && childData.classID === classID) {
              data.formattedDropOffTime = format(dropOffTime, 'hh:mm a');
              records.push({ key: doc.id, data: data });
          }
      }
  }

  return { tableData: records };
};

export const fetchAllStudentsRegister = async () => {
  const db = StartFirebase();
  const childrenCollection = collection(db, 'children');

  const querySnapshot = await getDocs(childrenCollection);

  let records = [];

  for (const doc of querySnapshot.docs) {
      let data = doc.data();
      let classID = data.classID;
      let classData = await fetchClassById(classID);

      data.class = classData ? classData.name : 'Unknown class';
      
      data.dropOffData = await fetchDropOffDataForStudentToday(doc.id); // Include drop-off data for today
      records.push({ key: doc.id, data: data });
      console.log(data)
  }

  return { tableData: records };
};

export const fetchStudentsRegister = async (classId) => {
  const db = StartFirebase();
  const childrenCollection = collection(db, 'children');

  // Query students by class ID if provided
  let studentsQuery = childrenCollection;
  if (classId) {
    studentsQuery = query(childrenCollection, where('classID', '==', classId));
  }

  const querySnapshot = await getDocs(studentsQuery);

  let records = [];

  for (const doc of querySnapshot.docs) {
      let data = doc.data();
      let classID = data.classID;
      let classData = await fetchClassById(classID);

      data.class = classData ? classData.name : 'Unknown class';
      
      data.dropOffData = await fetchDropOffDataForStudentToday(doc.id); // Include drop-off data for today
      records.push({ key: doc.id, data: data });
      console.log(data)
  }

  return { tableData: records };
};

// Function to check if a student has a drop-off record for today
const fetchDropOffDataForStudentToday = async (studentId) => {
  const db = StartFirebase();
  const dropOffCollection = collection(db, 'dropoffs');

  const querySnapshot = await getDocs(dropOffCollection);

  for (const doc of querySnapshot.docs) {
    let data = doc.data();
    let studentID = data.childID;
    const dropOffTime = new Date(parseInt(data.dropOffTime));

    // Check if the drop-off record is for today and matches the student ID
    if (studentID === studentId && isToday(parseInt(data.dropOffTime))) {
      let vehicleID = data.vehicleID
      let vehicleData = await fetVehicleDataById(vehicleID);
      data.formattedDropOffTime = format(dropOffTime, 'hh:mm a');
      data.vehicleModel = vehicleData ? vehicleData.makeModel : 'Unknown Vehicle';
      data.vehicleID = vehicleData ? vehicleData.vehicleID : 'unknown Vehicle'
      data.vehicleRegistration = vehicleData ? vehicleData.registrationNumber : 'unknown Vehicle'
      data.vehicleColor = vehicleData ? vehicleData.color : 'unknown Vehicle'
      data. present =true
      return data;
    }
  }

  // Return placeholder if no drop-off record found for today
  return {
    vehicleModel: '---',
    studentName: '---',
    vehicleRegistration: '---',
    vehicleColor: '---',
    dropOffTime: '---',
    pickUpTime: '---',
    driverName:'---',
    present: false
  };
};