import React from 'react'
import NavAvatar from './NavAvatar'

function Navbar() {
  return (
    <nav class="header-nav ms-auto">
        <ul >
            <NavAvatar/>
        </ul>
    </nav>
  )
}

export default Navbar
