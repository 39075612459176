import React from 'react'
import './main.css'


import Dashboard from './dashboard/Dashboard'
import PageTitles from './PageTitles'
import Header from '../header/Header'
import Sidebar from '../Sidebar/Sidebar'
function Main() {
  return (
    <>
        <Header/>
        <Sidebar/>
        <main id='main' className='main'>
        <PageTitles page='Dashboard'/>
        <Dashboard/>
        </main>
    </>
   
  )
}

export default Main
