import React, { useState } from 'react';
import './login.css';
import QRImage from '../images/qrcode.png';
import { notification } from 'antd';
import { auth } from '../firebase';
import { signInWithEmailAndPassword } from 'firebase/auth';
import Img from '../images/Gatepass_Website-03.png';
import Logo from '../images/IAS_Gatepass_App-02.png';

function Login() {
    const [loading, setLoading] = useState(false);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const handleLogin = () => {
        setLoading(true);
        signInWithEmailAndPassword(auth, email, password)
            .then((user) => {
                setLoading(false);
                // Handle successful login
            })
            .catch((error) => {
                openUpdateNotification('error');
                setLoading(false);
            });
    };

    const openUpdateNotification = (type) => {
        notification[type]({
            message: type === 'success' ? 'Success' : 'Error',
            description: type === 'success' ? 'Login successful' : 'Invalid username or password',
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        handleLogin();
    };

    return (
        <>
            <header className="main-header clearfix" role="header">
                <div className="logo">
                    <a href="#">
                        <img src={Logo} alt="" />
                    </a>
                </div>
                <a href="#menu" className="menu-link">
                    <i className="fa fa-bars"></i>
                </a>
                <nav id="menu" className="main-nav" role="navigation">
                    <ul className="main-menu">{/* <li><a href="#section1">About Us</a></li> */}</ul>
                </nav>
            </header>
            <section className="section coming-soon" data-section="section3">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6 col-xs-12">
                            <div className="continer centerIt">
                                <div>
                                    <img className="img-fluid" src={Img} alt="" width="700" height="700" />
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="right-content d-flex flex-column justify-content-center align-items-center" style={{ height: '100%' }}>
                                <form onSubmit={handleSubmit} style={{ borderRadius: '30px'}}>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <fieldset>
                                                <div className="input-group mb-3 flex">
                                                    <span className="input-group-text">
                                                        <i className="bi bi-person-fill"></i>
                                                    </span>
                                                    <input
                                                        type="email"
                                                        className="form-control"
                                                        placeholder="Username"
                                                        value={email}
                                                        onChange={(e) => setEmail(e.target.value)}
                                                        required
                                                    />
                                                </div>
                                            </fieldset>
                                        </div>
                                        <div className="col-md-12">
                                            <fieldset>
                                                <div className="input-group mb-3">
                                                    <span className="input-group-text">
                                                        <i className="bi bi-lock"></i>
                                                    </span>
                                                    <input
                                                        type="password"
                                                        className="form-control"
                                                        placeholder="Password"
                                                        value={password}
                                                        onChange={(e) => setPassword(e.target.value)}
                                                        required
                                                    />
                                                </div>
                                            </fieldset>
                                        </div>
                                        <div className="col-md-12">
                                            <fieldset>
                                                <button type="submit" className="button">
                                                    Sign In
                                                </button>
                                            </fieldset>
                                        </div>
                                    </div>
                                    <div className="top-content">
                                        <h6>Forgot your Password?</h6>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default Login;
