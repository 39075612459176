import React from 'react'
import './sidebar.css'
import { Link } from 'react-router-dom'
import { auth } from '../firebase';
function Sidebar() {
    const handleLogout = async () => {
        try {
          await auth.signOut();
        } catch (error) {
          console.error('Error signing out:', error.message);
        }
      };
  return (
    <aside id="sidebar" className="sidebar">
        <ul className="sidebar-nav" id="sidebar-nav">

            <li className="nav-item">
            <Link className="nav-link " to="/">
                <i className="bi bi-grid"></i>
                <span>Dashboard</span>
            </Link>
            </li>

            <li className="nav-item">
            <a className="nav-link collapsed" data-bs-target="#components-nav" data-bs-toggle="collapse" href="#">
                <i className="bi bi-person-square"></i><span>Guardians</span><i className="bi bi-chevron-down ms-auto"></i>
            </a>
            <ul id="components-nav" className="nav-content collapse " data-bs-parent="#sidebar-nav">
                <li>
                    <Link to='/parents'>
                        <i className="bi bi-circle"></i><span>Parents</span>
                    </Link>
                </li>
                <li>
                    <Link to='/drivers'>
                        <i className="bi bi-circle"></i><span>Drivers</span>
                    </Link>
                </li>
                <li>
                    <Link to='/third-part'>
                        <i className="bi bi-circle"></i><span>Other</span>
                    </Link>
                </li>
               
            </ul>
            </li>
            
            <li className="nav-item">
            <Link className="nav-link collapsed" to='/students'>
                <i className="bi bi-person-rolodex"></i>
                <span>Students</span>
            </Link>
            </li>

            <li className="nav-item">
            <Link className="nav-link collapsed" to='/drop-offs'>
                <i className="bi bi-car-front"></i>
                <span>Drop Offs</span>
            </Link>
            </li>
            <li className="nav-item">
            <Link className="nav-link collapsed" to='/pick-ups'>
                <i class="bi bi-person-check"></i>
                <span>Pick Ups</span>
            </Link>
            </li>

            <li className="nav-item">
            <Link className="nav-link collapsed" to='/security-personnel'>
                <i className="bi bi-person-lock"></i>
                <span>Security Personnel</span>
            </Link>
            </li>

            <li className="nav-item">
                <Link className="nav-link collapsed" to='/vehicles'>
                    <i class="bi bi-car-front-fill"></i>
                    <span>Vehicles</span>
                </Link>
            </li>

            <li className="nav-item">
            <a className="nav-link collapsed" href="" onClick={handleLogout}>
                <i className="bi bi-box-arrow-in-right"></i>
                <span>Logout</span>
            </a>
            </li>
        </ul>
    </aside>
  )
}

export default Sidebar
