import React, { useEffect, useState } from 'react'
import PageTitles from './PageTitles'
import { fetchClassRecords, fetchParentRecords, fetchStudentsData ,fetchParentRecordsByRelation, fetchStudentsPerParent} from '../firebase/fetchRecords';
import { Form, Modal, Spin, Pagination, Tooltip , notification} from 'antd';
import Header from '../header/Header';
import Sidebar from '../Sidebar/Sidebar';
import Select from 'react-select';
import { addDoc, collection, doc, updateDoc } from 'firebase/firestore';
import StartFirebase from '../firebase';
function Parents() {
    const [tableData, setTableData]=useState([])
    const [parentsData, setParentsData]=useState([])
    const [classData, setClassData]=useState([])
    const [loading, setLoading]=useState(true)
    const [addStudentsModal, setAddStudentsModal]= useState(false)
    const [editStudentsModal, setEditStudentsModal]=useState(false)
    const [currentPage, setCurrentPage] = useState(1);
    const pageSize = 10; 
    const [sorter, setSorter] = useState({});
    const [sortOrder, setSortOrder] = useState(null);
    const [sortField, setSortField] = useState(null);
    const [form] = Form.useForm();
    const [formData, setFormData] = useState(null);
    const [addParentsLoading, setAddParentLoading]=useState(false)
    const [addParentsModal, setAddParentModal]=useState(false)
    const [editParentsModal, setEditParentsModal]=useState(false)
    const [studentsTableModal, setStudentsTableModal]=useState(false)
    const handleChangeSort = (pagination, filters, sorter) => {
      setSorter(sorter);
  };

    const handleChangePage = (page) => {
        setCurrentPage(page);
    };
    const db = StartFirebase();

    useEffect(() => {
        fetchData();
        fetchClassData();
      }, []);
  
      const fetchData = async () => {
        try {
          const data = await fetchParentRecordsByRelation();
          setTableData(data);
        } catch (error) {
          console.error('Error fetching data:', error);
        } finally {
          setLoading(false);
        }
      };

      const fetchClassData = async () => {
        try {
          const data = await fetchClassRecords();
          setClassData(data);
        } catch (error) {
          console.error('Error fetching data:', error);
        } finally {
          setLoading(false);
        }
      };

    const handleSort = (field) => {
      if (field === sortField) {
          setSortOrder((prevOrder) => (prevOrder === 'asc' ? 'desc' : 'asc'));
      } else {
          setSortField(field);
          setSortOrder('asc');
      }
  };

  const sortedData = tableData?.tableData ? [...tableData.tableData] : [];

  if (sortField && sortOrder) {
      sortedData.sort((a, b) => {
          const aValue = a.data[sortField];
          const bValue = b.data[sortField];

          if (sortOrder === 'asc') {
              return aValue.localeCompare(bValue);
          } else {
              return bValue.localeCompare(aValue);
          }
      });
  }
   
  const [searchTerm, setSearchTerm] = useState('');

    const handleSearch = (e) => {
        setSearchTerm(e.target.value);
        setCurrentPage(1); // Reset page to 1 when searching
    };

   

    const handleEdit = (record) => {
      form.setFieldsValue({
        key: record.key,
        name: record.data.name,
        phoneNumber: record.data.phoneNumber,
        email: record.data.email,
        address: record.data.address,
        gender: record.data.gender,
        idNumber: record.data.idNumber,
        childRelation: record.data.childRelation,
      });
      console.log(record.data.name)
  
      setEditParentsModal(true)
    };
    const [api, contextHolder] = notification.useNotification();
    const openNotificationWithIcon = (type) => {
      notification[type]({
        message: type === 'success' ? 'Success' : 'Error',
        description: type === 'success' ? 'Parent added successfully' : 'Failed to add Student',
      });
    };
    const openUpdateNotification = (type) => {
      notification[type]({
        message: type === 'success' ? 'Success' : 'Error',
        description: type === 'success' ? 'Parent updated successfully' : 'Failed to update Student',
      });
    };
    
    const studentsCollectionRef = collection(db, "children");
    
    const parentsCollectionRef = collection(db, "parents");
    const addParents = async (values) => {
      setAddParentLoading(true);
      try {
        const docRef = await addDoc(parentsCollectionRef, {
          name: values.name,
          phoneNumber: values.phoneNumber,
          email: values.email,
          address: values.address,
          gender: values.gender,
          idNumber: values.idNumber,
          childRelation: values.childRelation,
          createdAt: Date.now(),
          updatedAt: Date.now(),
        });
    
        const parentID = docRef.id;
    
        await updateDoc(docRef, {
          parentID: parentID,
        });
        
    
        setAddParentLoading(false);
        setAddParentModal(false);
        openNotificationWithIcon('success');
      } catch (error) {
        setAddParentLoading(false);
        setAddParentModal(false);
        openNotificationWithIcon('error');
      }
    };
    
    const updateStudent = async (studentId, updatedValues) => {
      setAddParentLoading(true)
      try {
        await updateDoc(doc(studentsCollectionRef, studentId), {
          name: updatedValues.name,
          level: updatedValues.level,
          birthdate: updatedValues.birthdate,
          // parentID: updatedValues.parentID.map(option => option.value).join(','),
          classID: updatedValues.class,
          gender: updatedValues.gender,
          updatedAt: Date.now(),
        });
    
        setEditStudentsModal(false)
        setAddParentLoading(false)
        fetchData()
        openUpdateNotification('success')
      } catch (error) {
        setAddParentLoading(false)
        openUpdateNotification('error')
        setEditStudentsModal(false)
      }
    };
    const updateParent = async (parentID,values) => {
        setAddParentLoading(true);
        try {
          await updateDoc(doc(parentsCollectionRef, parentID), {
            name: values.name,
            phoneNumber: values.phoneNumber,
            email: values.email,
            address: values.address,
            gender: values.gender,
            idNumber: values.idNumber,
            childRelation: values.childRelation,
            updatedAt: Date.now(),
          });
      
          setAddParentLoading(false);
          setEditParentsModal(false);
          openUpdateNotification('success');
        } catch (error) {
          setAddParentLoading(false);
          setEditParentsModal(false);
          openUpdateNotification('error');
          console.log(error)
        }
      };

      const fetchStudentTableData = async (parentID) => {
        setLoading(true)
        try {
            const data = await fetchStudentsPerParent(parentID);
            setTableData(data);
            console.log(tableData)
            setLoading(false)
          } catch (error) {
            console.error('Error fetching data:', error);
          } finally {
            setLoading(false);
          }
        };
      
      const handleDetails = (record) => {
        fetchStudentTableData(record.data.parentID)   
        setStudentsTableModal(true)
      };
      const filteredData = sortedData.filter((record) => {
        return (
          record.data.email && record.data.email.toLowerCase().includes(searchTerm.toLowerCase()) ||
          record.data.name &&  record.data.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
          record.data.idNumber &&  record.data.idNumber.toString().toLowerCase().includes(searchTerm.toLowerCase()) ||
          record.data.gender &&  record.data.gender.toLowerCase().includes(searchTerm.toLowerCase()) ||
          record.data.childRelation &&  record.data.childRelation.toLowerCase().includes(searchTerm.toLowerCase()) ||
          record.data.phoneNumber &&  record.data.phoneNumber.toString().toLowerCase().includes(searchTerm.toLowerCase())
        );
    });
      const handleChangeSearch = (event) => {
        setSearchTerm(event.target.value);
        console.log(searchTerm)
    };
  return (
    <>
    {contextHolder}
    <Header/>
    <Sidebar/>
    <Modal
        style={{ marginTop: '20px' }}
        visible={addParentsModal}
        footer={false}
        closable={true}
        // className="modal fade" id="largeModal" tabindex="-1"
        onCancel={() => {
            setAddParentModal(false)
         
        }}
        width={800}
      >
            <div class="card">
            <div class="card-body">
              <h5 class="card-title">Add Parent</h5>
                <Spin spinning={addParentsLoading}>
                <Form
                onFinish={(values) => addParents(values)}
                >
                    <div class="row mb-3">
                        <label for="inputText" class="col-sm-2 col-form-label">Full Name</label>
                        <div class="col-sm-10">
                        <Form.Item
                            rules={[
                            {
                                required: true,
                                message: 'Please enter full name',
                            },
                            ]}
                            name="name"
                        >
                            <input type="text" class="form-control"/>
                            </Form.Item>
                        </div>
                    </div>
                    <div class="row mb-3">
                        <label for="inputText" class="col-sm-2 col-form-label">ID Number</label>
                        <div class="col-sm-10">
                        <Form.Item
                            rules={[
                            {
                                required: true,
                                message: 'Please enter ID Number',
                            },
                            ]}
                            name="idNumber"
                        >
                            <input type="text" class="form-control"/>
                            </Form.Item>
                        </div>
                    </div>
                    <div class="row mb-3">
                        <label for="inputEmail" class="col-sm-2 col-form-label">Email Address</label>
                        <div class="col-sm-10">
                        <Form.Item
                            rules={[
                            {
                                required: true,
                                message: 'Please enter email address',
                            },
                            ]}
                            name="email"
                        >
                            <input type="email" class="form-control"/>
                            </Form.Item>
                        </div>
                    </div>
                    <div class="row mb-3">
                        <label for="inputEmail" class="col-sm-2 col-form-label">Phone Number</label>
                        <div class="col-sm-10">
                        <Form.Item
                            rules={[
                            {
                                required: true,
                                message: 'Please enter phone number',
                            },
                            ]}
                            name="phoneNumber"
                        >
                            <input type="text" class="form-control"/>
                            </Form.Item>
                        </div>
                    </div>
                    <div class="row mb-3">
                        <label for="inputEmail" class="col-sm-2 col-form-label">Physical Address</label>
                        <div class="col-sm-10">
                        <Form.Item
                            rules={[
                            {
                                required: true,
                                message: 'Please enter address',
                            },
                            ]}
                            name="address"
                        >
                            <input type="text" class="form-control"/>
                            </Form.Item>
                        </div>
                    </div>
                    <div class="row mb-3">
                        <label for="inputNumber" class="col-sm-2 col-form-label">Gender</label>
                        <div class="col-sm-10">
                        <Form.Item
                            rules={[
                            {
                                required: true,
                                message: 'Please enter student date of birth',
                            },
                            ]}
                            name="gender"
                            >
                            <select class="form-select"  aria-label="Default select example">
                                <option selected>Select Gender</option>
                                <option value="Male">Male</option>
                                <option value="Female">Female</option>
                            </select>
                        </Form.Item>
                    </div>
                    </div>
                    <div class="row mb-3">
                        <label for="inputNumber" class="col-sm-2 col-form-label">Relationship</label>
                        <div class="col-sm-10">
                        <Form.Item
                            rules={[
                            {
                                required: true,
                                message: 'Please enter student date of birth',
                            },
                            ]}
                            name="childRelation"
                            >
                            <select class="form-select"  aria-label="Default select example">
                                <option selected>Select Relationship</option>
                                <option value="father">Father</option>
                                <option value="mother">Mother</option>
                            </select>
                        </Form.Item>
                    </div>
                    </div>
                   

                    <div class="row mb-3">
                    <label class="col-sm-2 col-form-label">Submit </label>
                    <div class="col-sm-10">
                        <button type="submit" class="btn button">Submit </button>
                    </div>
                    </div>
              </Form>
              </Spin>
                
                </div>
            </div>
      </Modal>
      <Modal
        style={{ marginTop: '20px' }}
        visible={editParentsModal}
        footer={false}
        closable={true}
        onCancel={() => {
            setEditParentsModal(false)
            
        }}
        width={800}
      >
            <div class="card">
            <div class="card-body">
              <h5 class="card-title">Edit Parent</h5>
                <Spin spinning={addParentsLoading}>
                <Form
                    form={form}
                    name="basic"
                    onFinish={(values) => updateParent(values.key, values)}
                    initialValues={{ mode: 'all' }}
                >
                    <div class="row mb-3">
                    <Form.Item name="key" hidden={true}></Form.Item>
                        <label for="inputText" class="col-sm-2 col-form-label">Full Name</label>
                        <div class="col-sm-10">
                        <Form.Item
                            rules={[
                            {
                                required: true,
                                message: 'Please enter full name',
                            },
                            ]}
                            name="name"
                        >
                            <input type="text" class="form-control"/>
                            </Form.Item>
                        </div>
                    </div>
                    <div class="row mb-3">
                        <label for="inputText" class="col-sm-2 col-form-label">ID Number</label>
                        <div class="col-sm-10">
                        <Form.Item
                            rules={[
                            {
                                required: true,
                                message: 'Please enter ID Number',
                            },
                            ]}
                            name="idNumber"
                        >
                            <input type="text" class="form-control"/>
                            </Form.Item>
                        </div>
                    </div>
                    <div class="row mb-3">
                        <label for="inputEmail" class="col-sm-2 col-form-label">Email Address</label>
                        <div class="col-sm-10">
                        <Form.Item
                            rules={[
                            {
                                required: true,
                                message: 'Please enter email address',
                            },
                            ]}
                            name="email"
                        >
                            <input type="email" class="form-control"/>
                            </Form.Item>
                        </div>
                    </div>
                    <div class="row mb-3">
                        <label for="inputEmail" class="col-sm-2 col-form-label">Phone Number</label>
                        <div class="col-sm-10">
                        <Form.Item
                            rules={[
                            {
                                required: true,
                                message: 'Please enter phone number',
                            },
                            ]}
                            name="phoneNumber"
                        >
                            <input type="text" class="form-control"/>
                            </Form.Item>
                        </div>
                    </div>
                    <div class="row mb-3">
                        <label for="inputEmail" class="col-sm-2 col-form-label">Physical Address</label>
                        <div class="col-sm-10">
                        <Form.Item
                            rules={[
                            {
                                required: true,
                                message: 'Please enter address',
                            },
                            ]}
                            name="address"
                        >
                            <input type="text" class="form-control"/>
                            </Form.Item>
                        </div>
                    </div>
                    <div class="row mb-3">
                        <label for="inputNumber" class="col-sm-2 col-form-label">Gender</label>
                        <div class="col-sm-10">
                        <Form.Item
                            rules={[
                            {
                                required: true,
                                message: 'Please enter student date of birth',
                            },
                            ]}
                            name="gender"
                            >
                            <select class="form-select"  aria-label="Default select example">
                                <option selected>Select Gender</option>
                                <option value="Male">Male</option>
                                <option value="Female">Female</option>
                            </select>
                        </Form.Item>
                    </div>
                    </div>
                    <div class="row mb-3">
                        <label for="inputNumber" class="col-sm-2 col-form-label">Relationship</label>
                        <div class="col-sm-10">
                        <Form.Item
                            rules={[
                            {
                                required: true,
                                message: 'Please enter student date of birth',
                            },
                            ]}
                            name="childRelation"
                            >
                            <select class="form-select"  aria-label="Default select example">
                                <option selected>Select Relationship</option>
                                <option value="father">Father</option>
                                <option value="mother">Mother</option>
                            </select>
                        </Form.Item>
                    </div>
                    </div>
                   

                    <div class="row mb-3">
                    <label class="col-sm-2 col-form-label">Submit </label>
                    <div class="col-sm-10">
                        <button type="submit" class="btn button">Submit </button>
                    </div>
                    </div>
              </Form>
              </Spin>
                
                </div>
            </div>
      </Modal>

      <Modal
        style={{ marginTop: '20px' }}
        visible={studentsTableModal}
        footer={false}
        closable={true}
        onCancel={() => {
            setStudentsTableModal(false)
            fetchData()
        }}
        width={1200}
      >
            <div class="card">
            <div class="card-body">
              <h5 class="card-title">Students</h5>
                <Spin spinning={loading}>
                <table className="table datatable">
                        <thead> 
                        <tr>
                            <th onClick={() => handleSort('name')}>Name </th>
                            <th onClick={() => handleSort('level')}>Level</th>
                            <th onClick={() => handleSort('class')}>Class</th>
                            <th >Date of Birth</th>
                            <th onClick={() => handleSort('gender')}>Gender</th>
                        </tr>
                            </thead>
                            <tbody>
                            {sortedData.slice((currentPage - 1) * pageSize, currentPage * pageSize).map((record, key) => (

                              <tr key={key}>
                                <td>{record.data.name}</td>
                                <td>{record.data.level}</td>
                                <td>{record.data.class}</td>
                                <td>{record.data.birthdate}</td>
                                <td>{record.data.gender}</td>
                              </tr>
                            ))}
                            </tbody>
                        </table>
                    </Spin>
                
                </div>
            </div>
      </Modal>
    
    <main id='main' className='main'>
        <PageTitles page='Parents'/>
        <section className="section">
            <div className="row">
                <div className="col-lg-12">
                    <div className="card">
                        <div className="card-body">
                        <h5 className="card-title">Parents</h5>
                        
                             <div class="card-body">
                                <button type="button" className="btn button" onClick={() => setAddParentModal(true)}><i class="bi bi-patch-plus"></i> Add Parents</button>
                              
                        </div>
                        <div className="col-lg-3 d-flex justify-content-end">
                          <div class="input-group">
                            <input type="search" class="form-control rounded" placeholder="Search" aria-label="Search" aria-describedby="search-addon" value={searchTerm} onChange={handleChangeSearch}/>
                            <button type="button" class="btn btn-outline-primary" data-mdb-ripple-init><i class="bi bi-search"></i></button>
                          </div>
                        </div>
                        <Spin spinning={loading}>
                          
                        <table className="table datatable">
                        <thead> 
                        <tr>
                            <th>Actions</th>
                            <th onClick={() => handleSort('name')}>Name </th>
                            <th onClick={() => handleSort('idNumber')}>ID Number</th>
                            <th onClick={() => handleSort('phoneNumber')}>Phone Number</th>
                            <th onClick={() => handleSort('email')}>Email</th>
                            <th onClick={() => handleSort('gender')}>Gender</th>
                            <th onClick={() => handleSort('childRelation')}>Relationship</th>
                        </tr>
                            </thead>
                            <tbody>
                            {filteredData.slice((currentPage - 1) * pageSize, currentPage * pageSize).map((record, key) => (

                              <tr key={key}>
                                  <td>
                                    <Tooltip title="Student Details">
                                      <button className="btn btn-outline btn-sm"  onClick={() => handleDetails(record)}>
                                        <i class="bi bi-eye"></i>
                                      </button>
                                    </Tooltip>
                                    <Tooltip title="Edit">
                                      <button className="btn btn-outline btn-sm ml-2" onClick={() => handleEdit(record)}>
                                        <i class="bi bi-pencil-square"></i>
                                      </button>
                                    </Tooltip>
                                </td>
                                <td>{record.data.name}</td>
                                <td>{record.data.idNumber}</td>
                                <td>{record.data.phoneNumber}</td>
                                <td>{record.data.email}</td>
                                <td>{record.data.gender}</td>
                                <td>{record.data.childRelation}</td>
                              </tr>
                            ))}
                            </tbody>
                        </table>
                        <Pagination
                            current={currentPage}
                            onChange={handleChangePage}
                            total={tableData?.tableData?.length}
                            pageSize={pageSize}
                            showSizeChanger={false}
                            style={{ marginTop: '20px', textAlign: 'center' }}
                        />
                        </Spin>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </main>
</>
  )
}

export default Parents
