// import icons
import 'bootstrap-icons/font/bootstrap-icons.css'
import 'remixicon/fonts/remixicon.css'

import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/js/bootstrap.min.js'

import './App.css';
import Header from './components/header/Header';
import Sidebar from './components/Sidebar/Sidebar';
import Main from './components/main/Main';
import Students from './components/main/dashboard/Students';
import Drivers from './components/main/Drivers';
import Parents from './components/main/Parents';
import Vehicles from './components/main/Vehicles'
import {BrowserRouter, Routes, Route, Link, useNavigate, useLocation} from "react-router-dom";
import ThirdPart from './components/main/ThirdPart';
import SecurityPersonnel from './components/main/SecurityPersonnel';
import Login from './components/auth/Login';
import { useState, useEffect } from 'react';
import { auth } from './components/firebase';
import DropOffs from './components/main/DropOffs';
import Pickups from './components/main/PickUps';

function App() {
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState(null);

  useEffect(() => {

    auth.onAuthStateChanged(user => {
        setUser(user);
        setLoading(false);
    })
}, [user])

  return (
    <>
      <BrowserRouter>
        <Routes>
          {user ? (
            <>
              <Route path="/" element={<Main />} />
              <Route path="/parents" element={<Parents />} />
              <Route path="/students" element={<Students />} />
              <Route path="/vehicles" element={<Vehicles />} />
              <Route path="/drivers" element={<Drivers />} />
              <Route path="/third-part" element={<ThirdPart />} />
              <Route path="/drop-offs" element={<DropOffs />} />
              <Route path="/pick-ups" element={<Pickups />} />
              <Route path="/security-personnel" element={<SecurityPersonnel />} />
              {/* <Route path="/dropoffs" element={<Dropoffs/>}/> */}
            </>
          ) : (
            <Route path="/" element={<Login />} />
          )}
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;